import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import filePNG from '../../../assest/file.png';
import AviThumbnail from '../../../assest/aviThumbnail.png';
import HEICThumbnail from '../../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../../assest/audioThumbnail.png';
import Textthumbnail from '../../../assest/textThumbnail.png';
import PdfThumbnail from '../../../assest/pdfThumbnail.webp';
import BackButtonImage from "../../../assest/backButton.svg"

// import ShopProductCard from '../../components/_dashboard/products/ProductCard';
import ShopProductCard from '../../../components/_dashboard/products/ProductCard';
const ProductDocumentStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
// material
import {
  Stack,
  TextField,
  Button,
  Grid,
  Card,
  Box,
  styled,
  Autocomplete,
  CircularProgress,
  Popper,
  ClickAwayListener,
  Divider,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getStorage } from 'firebase/storage';
import { collection, setDoc, doc, getDocs, where, query, updateDoc, arrayUnion } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { uploadFile } from '../user/UploadFile';
import { db } from '../../../App';
import EventPreview from '../event/EventPreview';
import FileView from '../event/FileView';
import SortByTitle from '../event/SortByTitle';
import { useLocation } from 'react-router-dom';
import DocumentsPreview from './DocumentsPreview';
import { MHidden } from 'src/components/@material-extend';
const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`
}));

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function AddDocumentsForm({ selectOption, setSetSelectOption }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [editFlag, setEditFlag] = useState(false)

  const [preview, setPreview] = useState(false);
  const [previewError, setpreviewError] = useState(false);

  // Facility
  const [facilityID, setFacilityID] = useState('');
  const [roomId, setRoomIds] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  // for empty change facility
  const [residentIDs, setresidentIDs] = useState([]);
  const [checkAllResi, setCheckAllResi] = useState(false);
  const [onceClickRoom, setOnceClickRoom] = useState(false);
  const [onceClickClient, setOnceClickClient] = useState(false);
  const [onceDone, setOnceDone] = useState(false);
  // ---------------------------for rooms and resident------------------------
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);
  const loadingRooms = openRoom && roomOptions.length === 0;
  const loadingFacility = openFacility && facilityOptions.length === 0;

  const loading = open && options.length === 0;

  const images =
    file.length != 0
      ? file.filter((image) => {
        console.log('image :: ', image);
        const file =
          (image.type != 'image' && image.type != 'video') ||
          (image.name && image.name.endsWith('.avi')) ||
          (image.name && image.name.endsWith('.mpeg')) ||
          (image.name && image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        if (type != 'file') {
          return image;
        }
      })
      : [];

  // for rooms Id array for resident
  const IDROOM = [];
  roomId.forEach((e) => {
    IDROOM.push(e.value);
  });

  const location = useLocation();
  // -------------------------resident Autocomplete----------------------------
  // useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }

  //   (async () => {
  //     // await sleep(1e3); // For demo purposes.
  //     let residentsCollection = query(collection(db, 'residents'), where('deletedAt', '==', null));
  //     if (localUser.role == "Facility Member" && localUser.residentIds) {
  //       if(localUser.residentIDs.length<=30){
  //         residentsCollection = query(collection(db, 'residents'), where('id', 'in', localUser.residentIds), where('deletedAt', '==', null))
  //       }else{

  //       }
  //     }
  //     const residentsPromise = await getDocs(residentsCollection);
  //     const opt = [];
  //     residentsPromise.forEach((result) => {
  //       opt.push({
  //         value: result.data().id,
  //         title: `${result.data().firstName} ${result.data().lastName}`,
  //         image: result.data().profileImgUrl,
  //         facilityId: result.data()?.facilityId,
  //         facilityName: result.data().facilityName,
  //         nextOfKin: result.data()?.nextOfKin ?? []
  //       });
  //     });
  //     if (active) {
  //       const optArr = SortByTitle(opt);
  //       // console.log(optArr)
  //       setOptions([...optArr]);
  //       // console.log(optArr)
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);
  // import { collection, getDocs, query, where } from "firebase/firestore";

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let residentsCollection = query(
        collection(db, "residents"),
        where("deletedAt", "==", null)
      );

      const opt = [];

      if (localUser.role === "Facility Member" && localUser.residentIds) {
        const chunkSize = 30;
        const residentIds = localUser.residentIds;

        if (residentIds.length > chunkSize) {
          const residentIdChunks = [];
          for (let i = 0; i < residentIds.length; i += chunkSize) {
            residentIdChunks.push(residentIds.slice(i, i + chunkSize));
          }
          console.log("chunks are : ", residentIdChunks)

          const fetchPromises = residentIdChunks.map(async (chunk) => {
            const q = query(
              collection(db, "residents"),
              where("id", "in", chunk),
              where("deletedAt", "==", null)
            );
            return getDocs(q);
          });

          const results = await Promise.all(fetchPromises);
          results.forEach((residentsPromise) => {
            residentsPromise.forEach((result) => {
              opt.push({
                value: result.data().id,
                title: `${result.data().firstName} ${result.data().lastName}`,
                image: result.data().profileImgUrl,
                facilityId: result.data()?.facilityId,
                facilityName: result.data().facilityName,
                nextOfKin: result.data()?.nextOfKin ?? [],
              });
            });
          });
        } else {
          residentsCollection = query(
            collection(db, "residents"),
            where("id", "in", residentIds),
            where("deletedAt", "==", null)
          );
          const residentsPromise = await getDocs(residentsCollection);
          residentsPromise.forEach((result) => {
            opt.push({
              value: result.data().id,
              title: `${result.data().firstName} ${result.data().lastName}`,
              image: result.data().profileImgUrl,
              facilityId: result.data()?.facilityId,
              facilityName: result.data().facilityName,
              nextOfKin: result.data()?.nextOfKin ?? [],
            });
          });
        }
      } else {
        const residentsPromise = await getDocs(residentsCollection);
        residentsPromise.forEach((result) => {
          opt.push({
            value: result.data().id,
            title: `${result.data().firstName} ${result.data().lastName}`,
            image: result.data().profileImgUrl,
            facilityId: result.data()?.facilityId,
            facilityName: result.data().facilityName,
            nextOfKin: result.data()?.nextOfKin ?? [],
          });
        });
      }

      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);


  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);



  useEffect(() => {
    if (!openFacility) {
      setFacilityOptions([]);
    }
  }, [openFacility]);

  // ---------------------------for rooms and resident-------------------------------------------

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().required('Description is required.').nullable(),
    facility: Yup.string().required('Group is required').nullable(),
    roomsId: Yup.array().min(1, 'at least 1 location is required').required('required'),
    residentsId: Yup.array().min(1, 'at least 1 client is required').required('required'),
    date: Yup.date().required('Date is required'),
    visibleFlag: Yup.bool(),
    fileName: Yup.string().required('file name is required')
  });
  const RegisterSchemaDraft = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().required('Description is required.').nullable(),
    facility: Yup.string().nullable(),
    roomsId: Yup.array().nullable(),
    residentsId: Yup.array().nullable(),
    date: Yup.date().nullable(),
    visibleFlag: Yup.bool(),
    photo: Yup.string('Atleast one document is required'),
    // fileName:Yup.string().required('file name is required')
  });
  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e) => {
    console.log(e)
    const s = file.filter((item, index) => index !== e);
    console.log(s)
    setFile(s);
    setFieldValue('fileName', '')
  };

  const handleFileUpload = async (e) => {
    console.log(e.target.files)
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {

      arr.push(e.target.files[i]);
    }
    const fileName = arr[0]?.name?.split('.').slice(0, -1).join('.');
    console.log(fileName)
    if (arr.length + file.length > 30) {
      e.target.value = "";
      return NotificationManager.error('maximum 30 files to upload');
    }
    setFieldValue('fileName', fileName)
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      if (size / 1024 > 20480) {
        e.target.value = "";
        return NotificationManager.error('File size is greater than 20 mb');
      }
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application' && !arr[i].name.endsWith('.mpeg')) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          name: arr[i]?.name,
          size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
    }
    setFile([...file, ...obj]);
    e.target.value = "";
  };

  const saveDraft = () => { };
  // console.log(`flag value is is : ${location.state.visibileFlag}`)
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      photo: '',
      residentsId: [],
      roomsId: [],
      facility: '',
      visibleFlag: location.state?.visibileFlag ?? false,
      date: new Date(),
      fileName: ''
    },
    validationSchema: draft ? RegisterSchemaDraft : RegisterSchema,
    onSubmit: async () => {
      if (previewError) {
        return setPreview(true);
      }
      await AddEventFunc(draft ? 1 : 0);
    }
  });

  useEffect(() => {
    if (location.state?.fileName && !onceDone) {
      console.log('location :: ', location.state);

      setFile(location.state.medias);
      setFieldValue('fileName', location.state?.fileName?.split('.').slice(0, -1).join('.') ?? '')
      console.log(`flag value is : ${location.state?.visibileFlag ? "true" : "false"}`)
      setFieldValue('visibleFlag', location.state?.visibileFlag)
      console.log(`flag value after is : ${getFieldProps('visibleFlag').value ? "true" : "false"}`)
      setFieldValue('date', new Date(location.state.createdAt))


      if (true) {
        (async () => {
          // await sleep(1e3); // For demo purposes.
          const residentsCollection = query(
            collection(db, 'residents'),
            where('id', 'in', location?.state?.residentsId),
            where('deletedAt', '==', null)
          );
          const residentsPromise = await getDocs(residentsCollection);
          const opt = [];
          residentsPromise.forEach((result) => {
            opt.push({
              value: result.data().id,
              title: `${result.data().firstName} ${result.data().lastName}`,
              image: result.data().profileImgUrl,
              facilityId: result.data()?.facilityId,
              facilityName: result.data().facilityName,
            });
          });

          const optArr = SortByTitle(opt);

          setOptions([...optArr]);
          setresidentIDs(optArr);
          console.log(optArr)
          setFieldValue('residentsId', optArr);
          setFieldValue('resident', optArr[0] || null); // Set the first value.
          setOnceDone(true);
        })();
      }

      // console.log("get ", getFieldProps('facilityName'))
    }
  }, []);

  const AddEventFunc = async (drft) => {
    console.log(drft ?? false);
    const draft = drft ?? false;
    console.log(draft);

    const residents = getFieldProps('residentsId').value;

    const residentNameNotification = residents?.[0]?.facilityId
    const date = getFieldProps('date').value;
    const flag = getFieldProps('visibleFlag').value
    const fullFileName = file?.[0]?.name
    const extension = fullFileName?.substring(fullFileName.lastIndexOf(".") + 1);
    const fileName = getFieldProps('fileName').value

    const storage = getStorage();
    let clientsDocumentRefernce;
    if (draft) {
      clientsDocumentRefernce = doc(collection(db, 'drafts'));
    } else {
      clientsDocumentRefernce = doc(collection(db, 'ClientDocuments'));
    }

    // --------------resident array---------------
    const residentsId = [];
    const nextOkKins = [];
    residents.forEach((element) => {
      residentsId.push(element.value);
      if (element?.nextOfKin) {
        element.nextOfKin.map(item => nextOkKins.push(item))
      }
    });


    // console.log("next of kins are : ", nextOkKins)
    // console.log(residentsId);

    // return
    // --------------resident array---------------

    const medias = [];
    let itemsProcessed = 0;

    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }
    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 10000);
    if (file.length > 0) {
      file.forEach(async (element, index, array) => {
        const mediaId = uuidv4();

        console.log("element is : ", element)
        console.log(location.state?.medias)
        // return

        if (element.type === 'video') {
          const downloadUrl = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? element?.downloadUrl : await uploadFile(
            'ClientDocuments',
            clientsDocumentRefernce,
            element?.file ?? element,
            element.type,
            mediaId
          );
          const thumbnailId = uuidv4();
          const downloadUrlThumbnail = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? element?.thumbnail : await uploadFile(
            'ClientDocuments',
            clientsDocumentRefernce,
            element.thumbnail,
            'thumbnail',
            thumbnailId
          );
          const mediaData = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? { ...location?.state?.medias[0] } : {
            downloadUrl,
            thumbnailId,
            thumbnail: downloadUrlThumbnail,
            name: element?.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          }

          medias.push(mediaData);
        }

        if (element.type === 'image') {
          const downloadUrl = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? element?.downloadUrl : await uploadFile(
            'ClientDocuments',
            clientsDocumentRefernce,
            element?.file ?? element,
            element.type,
            mediaId
          );
          // console.log(downloadUrl)
          const mediaData = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? { ...location?.state?.medias[0] } : {
            downloadUrl,
            thumbnailId: null,
            thumbnail: null,
            name: element?.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          }
          medias.push(mediaData);

        }

        if (element.type != 'image' && element.type != 'video') {

          // console.log(location.state?.medias[0]?.id)
          // console.log(element.id)
          // console.log(`${(location.state?.medias[0]?.id == element?.id) ? 'yes' : "no"}`)
          const downloadUrl = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? element?.downloadUrl : await uploadFile(
            'ClientDocuments', clientsDocumentRefernce, element?.file ?? element, element.type, mediaId);
          const thumbnailId = uuidv4();
          const mediaData = (location.state?.medias[0]?.id && location.state?.medias[0]?.id == element?.id) ? { ...location?.state?.medias[0] } : {
            downloadUrl,
            thumbnailId: thumbnailId,
            thumbnail: element.thumbnail,
            name: element?.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          }
          medias.push(mediaData);
          // console.log("media data is : ", mediaData)
          // const downloadUrl = await uploadFile('ClientDocuments', clientsDocumentRefernce, element?.file ?? element, element.type, mediaId);
          // const thumbnailId = uuidv4();
          // medias.push({
          //   downloadUrl,
          //   thumbnailId: thumbnailId,
          //   thumbnail: element.thumbnail,
          //   name: element?.name,
          //   type: element.type,
          //   size: Math.floor(element.size / 1024, 3),
          //   id: mediaId
          // });
        }

        console.log("media is : ", medias)
        if (medias.length == 0 || !medias?.[0]) {
          NotificationManager.error('Unable to upload media')
          return
        }
        itemsProcessed += 1;
        if (itemsProcessed === array.length) {
          if (draft) {
            setDoc(clientsDocumentRefernce, {
              id: clientsDocumentRefernce.id,
              title: "Clients Documents",
              type: 'documents',
              createdAt: date,
              creatorId: JSON.parse(localStorage.getItem('Rel8User')).id,
              object: {
                id: clientsDocumentRefernce.id,
                medias,
                postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                  }`,
                createdAt: date,
                residentsId,
                visibileFlag: flag ?? false,
                type: 'documents',
                fileName: `${fileName}.${extension}` ?? ''
              },
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Draft saved Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                setDraft(false);
                setDraftLoading(false);
                navigate('/home/drafts', { replace: true });
              })
              .catch((err) => console.log(err));
          } else {
            setDoc(clientsDocumentRefernce, {
              id: clientsDocumentRefernce.id,
              medias,
              postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
              createdAt: date,
              residentsId,
              visibileFlag: flag ?? false,
              fileName: `${fileName}.${extension}` ?? ''
            })
              .then(async (res) => {
                residentsId.map(async (clientId) => {
                  const clientRef = doc(db, 'residents', clientId)
                  await updateDoc(clientRef, {
                    documentsRef: arrayUnion(clientsDocumentRefernce)
                  })
                })
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Documents Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                // notification here....
                const object = {
                  id: clientsDocumentRefernce?.id,
                  createdAt: date,
                  views: [],
                  medias,
                  residentIds: residentsId,
                  residentsId: residentsId,
                  authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname}`,
                  postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                  createdById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                  visibleFlag: flag ?? false,
                  fileName: `${fileName}.${extension}` ?? '',
                  facilityId: residents?.[0]?.facilityId,
                  facilityName: residents?.[0]?.facilityName,
                  residentName: residents?.[0]?.title,
                  nextOfKins: nextOkKins
                }
                // -----------------------------------------Comment Notifications------------------------------------
                const NotifiRef = doc(collection(db, 'notifications'));
                await setDoc(NotifiRef, {
                  id: NotifiRef.id,
                  body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname
                    } has added a new document`,
                  documentId: clientsDocumentRefernce.id,
                  createdAt: date,
                  profileImgUrl: JSON.parse(localStorage.getItem('Rel8User'))?.avatarUrl ?? null,
                  title: 'A new document added',
                  topic: `${residentNameNotification ?? ''}_documents`,
                  viewedBy: [],
                  senderId: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                  receiverId: '',
                  object,
                  senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname}
            ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname}`,
                  type: 'documents'
                }).then(() => {
                  console.log("notification added ")
                });

                navigate('/content/documents', { replace: true });

              })
              .catch((err) => console.log(err));
          }
        }
      });
      // ************************************** For with out image ****************************************************
    }
    else {
      if (draft) {
        return setDoc(clientsDocumentRefernce, {
          id: clientsDocumentRefernce.id,
          type: 'documents',
          createdAt: date,
          creatorId: JSON.parse(localStorage.getItem('Rel8User')).id,
          object: {
            id: clientsDocumentRefernce.id,
            postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
            authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
            createdAt: date,
            medias,
            residentsId,
            visibileFlag: flag ?? false,
            type: 'documents',
            fileName: `${fileName}.${extension}` ?? ''
          }
        })
          .then((res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft saved Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            setDraft(false);
            setDraftLoading(false);
            navigate('/home/drafts', { replace: true });
          })
          .catch((err) => console.log(err));
      }
      //  else {
      setDoc(clientsDocumentRefernce, {
        id: clientsDocumentRefernce.id,
        medias,
        postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        createdAt: date,
        residentsId,
        visibileFlag: flag ?? false,
        fileName: `${fileName}.${extension}` ?? ''
      })
        .then(async (res) => {
          residentsId.map(async (clientId) => {
            const clientRef = doc(db, 'residents', clientId)
            await updateDoc(clientRef, {
              documentsRef: arrayUnion(clientsDocumentRefernce)
            })
          })
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Documents Added Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/content/documents', { replace: true });


          // -----------------------------------------Comment Notifications------------------------------------
        })
        .catch((err) => console.log(err));
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldTouched,
    setFieldValue
  } = formik;

  // for resident
  const handleClickAwayResi = (e) => {
    setOpen(false);
  };


  return (
    <Box sx={{ width: '100%', minWidth: '100%' }}>
      {selectOption == 'preview' ? (
        <DocumentsPreview
          file={file}
          title={{ ...getFieldProps('title') } ?? ''}
          description={{ ...getFieldProps('description') }}
          visibleFlag={{ ...getFieldProps('visibleFlag') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          head1="Publish"
          head2="Add Notice"
          section='Documents'
          // setDraft={setDraft}
          residentName={{ ...getFieldProps('residentsId').value }}
          handleSubmit={handleSubmit}
          date={getFieldProps('date').value}
          setSelectOption={setSetSelectOption}
          fileName={getFieldProps('fileName').value}
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
          {selectOption != 'add' && <div style={{ marginBottom: "12px" }} onClick={() => {
            if (selectOption == 'schedule') {
              setSetSelectOption('add')
            }
          }}><img src={BackButtonImage} style={{ width: "35px", cursor: "pointer" }} /></div>}

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>

                {selectOption == 'schedule' && (
                  <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Date
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          format="dd/MM/yyyy"
                          inputFormat="dd/MM/yyyy"
                          label="Date"
                          {...getFieldProps('date')}
                          onChange={(e, value) => setFieldValue('date', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('date', true, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                          maxDate={new Date()}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}

                {/* ************************************************************************************************************************* */}

                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      {/* <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Client
                      </div> */}
                      <ClickAwayListener onClickAway={handleClickAwayResi}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            // disableCloseOnSelect
                            // multiple
                            // limitTags={3}
                            // disabled={IDROOM.length === 0 ?? false}
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              // alert('selected')
                              if (residentIDs.length != 0) {
                                setOnceClickClient(true);
                              }
                              if (reason === 'selectOption') {
                                console.log("new Value is : ", [newValue])
                                setFieldValue('residentsId', [newValue] ?? []);
                                setresidentIDs([newValue]);
                                setOpen(false)
                              } else if (reason === 'removeOption') {
                                setFieldValue('residentsId', [newValue] ?? []);
                                setresidentIDs([newValue]);
                              } else if (reason === 'clear') {
                                setresidentIDs([]);
                                setFieldValue('residentsId', []);
                              }
                              setCheckAllResi(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={options}
                            value={residentIDs[0] ?? null}
                            loading={loading}
                            // PopperComponent={(param) => (
                            //   <PopperStyledComponent {...param}>
                            //     <Box {...param} />
                            //     <Divider />
                            //     <Box
                            //       sx={{
                            //         backgroundColor: 'white',
                            //         height: '45px',
                            //         textOverflow: 'ellipsis',
                            //         overflow: 'hidden',
                            //         whiteSpace: 'nowrap'
                            //       }}
                            //     >
                            //       <Checkbox
                            //         checked={checkAllResi}
                            //         onChange={checkAllChangeResi}
                            //         id="check-all"
                            //         sx={{ marginRight: '8px' }}
                            //         onMouseDown={(e) => e.preventDefault()}
                            //       />
                            //       Select All
                            //     </Box>
                            //   </PopperStyledComponent>
                            // )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={
                                  location.state && residentIDs.length == 0 && !onceClickClient
                                    ? 'Loading...'
                                    : 'Select Client'
                                }
                                {...getFieldProps('resident')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.residentsId && errors.residentsId)}
                                helperText={touched.residentsId && errors.residentsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}
                {/* ************************************************************************************************************************* */}

                {/* ------------------------------------------------------------- */}
                {selectOption == 'add' && <FormControlLabel
                  control={<Checkbox defaultChecked={getFieldProps('visibleFlag').value} />}
                  label="Visible to next of kin only"
                  style={{ fontSize: "16px" }}
                  {...getFieldProps('visibleFlag')}
                  onChange={(e, value) => setFieldValue('visibleFlag', value)}
                />}
                {/* add documents */}
                {selectOption == 'add' && (
                  <>
                    {true && (
                      <div className="grid">
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <>
                                {!(
                                  (item.type != 'image' && item.type != 'video') ||
                                  item.name.endsWith('.avi') ||
                                  item.name.endsWith('.mpeg')
                                ) && (
                                    <div
                                      key={item.id}
                                      style={{ position: 'relative' }}
                                      className={
                                        item === images[0] && images.length > 7
                                          ? 'firstIndex'
                                          : 'allIndex'
                                      }
                                    >
                                      {' '}
                                      <MHidden width='mdUp'>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '-3px',
                                            right: '-18px',
                                            zIndex: 100,
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: '5px'
                                          }}
                                        >
                                          <Icon
                                            icon="ci:close-big"
                                            onClick={() => {
                                              setEditFlag(false)
                                              deleteFile(index)
                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              backgroundColor: 'white',
                                              borderRadius: '100%',
                                              outline: '1px',
                                              outlineColor: '#9A76F5',
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                          <Icon
                                            icon="flowbite:edit-outline"
                                            onClick={() => setEditFlag(true)}
                                            style={{
                                              cursor: 'pointer',
                                              backgroundColor: 'white',
                                              borderRadius: '100%',
                                              outline: '1px',
                                              outlineColor: '#9A76F5',
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                        </div>
                                      </MHidden>
                                      <MHidden width='mdDown'>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '-3px',
                                            right: '-27px',
                                            zIndex: 100,
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: '5px'
                                          }}
                                        >
                                          <Icon
                                            icon="ci:close-big"
                                            onClick={() => {
                                              setEditFlag(false)
                                              deleteFile(index)
                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              backgroundColor: 'white',
                                              borderRadius: '100%',
                                              outline: '1px',
                                              outlineColor: '#9A76F5',
                                              width: "24px",
                                              height: "24px"
                                            }}
                                          />
                                          <Icon
                                            icon="flowbite:edit-outline"
                                            onClick={() => setEditFlag(true)}
                                            style={{
                                              cursor: 'pointer', width: "24px",
                                              height: "24px"
                                            }}
                                          />
                                        </div>
                                      </MHidden>
                                      <Card>

                                        {/* <ProductImageStyle
                                          alt="Image"
                                          src={item.thumbnail ?? item.downloadUrl}
                                        /> */}
                                        <ShopProductCard img={item} Index={index} images={images} />

                                      </Card>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </div>
                    )}
                    {true && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {file.length > 0 &&
                          file.map((item, index) => {
                            const fileNameValue = getFieldProps('fileName').value
                            const extension = file[0]?.name?.substring(file[0].name.lastIndexOf(".") + 1);
                            return (
                              <>
                                {((item.type != 'image' && item.type != 'video') ||
                                  item.name.endsWith('.avi') ||
                                  item.name.endsWith('.mpeg')) && (
                                    <Box
                                      sx={{
                                        maxWidth: '250px',
                                        width: 'full',
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        gap: '8px',
                                        alignItems: 'center'
                                      }}>

                                        <Icon
                                          icon="ci:close-big"
                                          onClick={() => {
                                            setEditFlag(false)
                                            deleteFile(index)
                                          }}
                                          style={{
                                            cursor: 'pointer', width: "24px",
                                            height: "24px"
                                          }}
                                        />
                                        <Icon
                                          icon="flowbite:edit-outline"
                                          onClick={() => setEditFlag(true)}
                                          style={{
                                            cursor: 'pointer', width: "24px",
                                            height: "24px"
                                          }}
                                        />
                                      </div>
                                      <Box
                                        sx={{
                                          maxWidth: '50px',
                                          maxHeight: '60px',
                                          minWidth: '50px',
                                          minHeight: '60px',
                                          overflow: 'hidden'
                                        }}
                                      >
                                        <img
                                          src={item.thumbnail}
                                          style={{ width: '100%', object: 'contain', height: '100%' }}
                                        />
                                      </Box>
                                      <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                        {fileNameValue && fileNameValue.length > 25
                                          ? fileNameValue.slice(0, 22) + '...'
                                          : fileNameValue}
                                        {'.' + extension + ' ('}
                                        {Number(item.size / 1024).toFixed(2) ?? ''}
                                        {' KB)'}
                                      </div>
                                    </Box>
                                  )}
                              </>
                            );
                          })}
                      </Box>
                    )}
                    {file.length == 0 && <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "start" }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Button variant="contained" component="label">
                          <span style={{ fontSize: "24px", lineHeight: '20px', marginRight: "5px" }}>+</span> Add attachment
                          <input
                            type="file"
                            hidden
                            // multiple
                            fullWidth
                            accept="image/*,video/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3, .heic"
                            onChange={handleFileUpload}
                            error={Boolean(touched.photo && errors.photo)}
                            helperText={touched.photo && errors.photo}
                          />
                        </Button>
                      </Stack>
                      {touched.photo && !file.length > 0 && <p style={{ color: "#ff5b55", fontSize: "13px" }}>Attach atleast one document</p>}

                    </div>}
                    {file.length !== 0 && editFlag && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                      >
                        <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                          Edit File Name
                        </div>

                        <TextField
                          fullWidth
                          // label="Title"
                          {...getFieldProps('fileName')}
                          error={Boolean(touched.fileName && errors.fileName)}
                          helperText={touched.fileName && errors.fileName}
                        />
                      </Box>
                    </Stack>}

                  </>
                )}

                {(selectOption == 'add' || selectOption == 'schedule') && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      // type="submit"
                      variant="outlined"
                      loading={draftLoading}
                      onClick={() => {
                        setDraft(true)
                        AddEventFunc(true)
                      }}
                    >
                      Save drafts
                    </LoadingButton>
                    <LoadingButton
                      fullWidth
                      size="large"
                      // type="submit"
                      onClick={() => {
                        if (selectOption == 'add') {
                          // alert('scheduling')
                          if (!getFieldProps('residentsId').value.length) {
                            setFieldTouched('residentsId')
                          }
                          if (!getFieldProps('fileName').value) {
                            setFieldTouched('fileName')
                          }
                          if (!file.length > 0) {
                            setFieldTouched('photo')
                          }
                          if (getFieldProps('residentsId').value.length && file.length > 0 && getFieldProps('fileName').value) {
                            setSetSelectOption('schedule');
                          }
                        } else if (selectOption == 'schedule') {
                          setSetSelectOption('preview')
                        }
                      }}
                      variant="contained"
                      loading={isLoading}
                      disabled={draft}
                    >
                      {selectOption == 'add' ? 'Schedule' : selectOption == 'schedule' && "Preview"}
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Box>
  );
}
