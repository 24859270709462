import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  styled,
  Button,
  Grid,
  Card,
  Box,
  ClickAwayListener
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import AsyncSelect from 'react-select/async';
import {
  collection,
  getDocs,
  setDoc,
  doc,
  query,
  where,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
import axios from 'axios';
import Spinner from 'react-spinner-material';
import { options } from 'numeral';
import { addFacility } from '../../../pages/Facilities';
import { db } from '../../../App';
import { subRoleFacility } from '../../subRole/SubRole';
import { deleteFolder } from '../user/deleteFiles';
import SortByTitle from '../event/SortByTitle';
import { cloudFuncBaseUrl } from 'src/utils/config';
import getAndUpdateDoc from './getAndUpdateDoc';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------
const addFacilityMemberAuth = (email, facilityId, facilityName) => {
  const data = {
    email,
    facilityId,
    facilityName
  };
  return axios.post('https://rel8-cloud-functions.herokuapp.com/addFacility', data);
};

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');

export default function UpdateFacilityMembersForm({
  email,
  facilityId,
  facilityName,
  firstName,
  lastName,
  jobTitle,
  organisation,
  avatarUrl,
  subRole,
  organisationId
}) {
  console.log("organization id is : ", organisationId)
  const oldEmail = email;
  const navigate = useNavigate();
  const location = useLocation();

  const [deleteImage, setDeleteImage] = useState('');

  const [image, setImage] = useState(location?.state?.avatarUrl);
  const [imageState, setImageState] = useState('');
  const [spinnerIsLoading, setSpinnerIsLoading] = useState(true);
  const [colourOptions, setColourOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [defaultOptions, setDefaultopt] = useState([]);
  const [options, setOptions] = useState([]);
  const [openOrg, setOpenOrg] = useState(false);
  const [optionOrg, setOptionOrg] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(organisationId ?? '');
  const [isLoadingOrg, setisLoadingOrg] = useState(false);
  const [openRole, setOpenRole] = useState(false)


  const loading = open && options.length === 0;

  async function getOrganisation() {
    setisLoadingOrg(true);
    const orgRef = collection(db, 'organizations');
    onSnapshot(orgRef, (snapshot) => {
      const users = [];
      snapshot.docs.forEach((result) =>
        users.push({ title: result.data().organizationName, value: result.data().id })
      );
      setOptionOrg(users);
      const filteredOption = users?.find(item => item.title == organisation)
      if (!organisationId) {
        setFieldValue("organisation", { filteredOption })
        setSelectedOrgId(filteredOption?.value)
        console.log(filteredOption)
      }
      setisLoadingOrg(false);
    });
  }

  const filterColors = (inputValue) =>
    colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    // _this.setState({ inputValue: inputValue });
    return inputValue;
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('organizationId', '==', selectedOrgId)
        );
      }
      if (!selectedOrgId) return;
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        console.log(optArr);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // --------------------------------------Default value for Facility ---------------------
  useEffect(async () => {
    const roomCollection = query(collection(db, 'facilites'), where('id', 'in', facilityId));
    const roomsPromise = await getDocs(roomCollection);
    const opt = [];
    roomsPromise?.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: result.data().name
      });
    });
    const optArr = SortByTitle(opt);
    setFieldValue('facility', optArr);
    setDefaultopt(optArr);
  }, []);
  // --------------------------------------Default value for Facility ---------------------

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // facility: Yup.string().required('facilty is required'),
    // firstName: Yup.string().required('First name is required'),
    facility: Yup.array()
      .min(1, selectedOrgId ? 'at least 1 group is required' : 'Select the organisation first')
      .required('required'),
    lastName: Yup.string().required('Last name is required'),
    jobTitle: Yup.string().required('Job title is required'),
    organisation: Yup.object()
      .nullable()
      .test('title', 'Organisation is required', function (value) {
        return value !== null;
      }),
    subRole: Yup.number().required('Role is required')
  });

  useEffect(() => {
    if (!organisationId)
      getOrganisation()
  }, [])

  const formik = useFormik({
    initialValues: {
      email,
      firstName,
      lastName,
      jobTitle,
      organisation: { title: organisation, value: organisationId ?? '' },
      facility: defaultOptions,
      // facility: [],
      // facilityName,
      subRole
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      console.log(location.state.docId);
      setIsLoading(true);
      const email = getFieldProps('email').value;
      const facility = getFieldProps('facility').value;
      const firstname = getFieldProps('firstName').value;
      const lastname = getFieldProps('lastName').value;
      const facilityName = getFieldProps('facilityName').value;
      const jobTitle = getFieldProps('jobTitle').value;
      const organisation = getFieldProps('organisation').value?.title ?? getFieldProps('organisation').value?.filteredOption?.title ?? '';
      const organisationId = getFieldProps('organisation').value?.value ?? getFieldProps('organisation').value?.filteredOption?.value ?? '';
      console.log("organisation id is : ",organisationId)
      const subRole = getFieldProps('subRole').value;

      // console.log(`organisation is : ${organisation} , organisation id is : ${organisationId}`)
      // setIsLoading(false)
      // return

      const userQuery = query(collection(db, 'users'), where('email', '==', email))

      const userData = await getDocs(userQuery)
      if (email == oldEmail || userData.empty) {
        console.log("email dosen't exisst")
      } else {
        setIsLoading(false)
        NotificationManager.error('User with this email already exists')
        console.log("already exists")
        return
      }

      try {
        // -----------update user collection-----------

        const facilityIds = [];
        facility.forEach((element) => {
          facilityIds.push(element.value);
        });
        // resident
        const residentRef = query(
          collection(db, 'residents'),
          where('facilityId', 'in', facilityIds)
        );
        const refData = await getDocs(residentRef);

        const residentIds = [];
        refData?.forEach((res) => {
          residentIds.push(res.data()?.id);
        });
        console.log('residentIds !!!! ', residentIds);
        // resident

        let NewAvatarUrl;
        if (imageState) {
          const storage = getStorage();
          const storageRef = ref(storage, `users/${location?.state?.docId}/profile`);
          const metadata = {
            contentType: 'image/jpeg'
          };
          const uploadTask = await uploadBytesResumable(storageRef, image, metadata);
          NewAvatarUrl = await getDownloadURL(uploadTask.ref);
        }

        if (email !== oldEmail) {
          const updatedEmail = await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUsersPassword`,
            data: {
              id: location?.state?.docId,
              email,
              type: 'email'
            }
          });
        }

        if (deleteImage) {
          await deleteFolder(deleteImage);
        }

        let deletedImg;
        if (deleteImage) {
          deletedImg = null;
        } else {
          deletedImg = location?.state?.avatarUrl;
        }

        const updatedUser = await axios({
          method: 'post',
          url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUserEveryWhere`,
          data: {
            id: location?.state?.docId,
            email,
            residentIds,
            facilityIds,
            firstname,
            lastname,
            organisation,
            organization: organisation,
            organizationId: organisationId,
            avatarUrl: imageState ? NewAvatarUrl : deletedImg,
            subRole,
            jobTitle,
            role: 'Facility Member'
          }
        });

        // check 2 arrays are same or not
        function checkArrays(arrA, arrB) {
          if (arrA.length !== arrB.length) return false;
          var cA = arrA.slice().sort().join(',');
          var cB = arrB.slice().sort().join(',');
          return cA === cB;
        }

        // console.log(checkArrays(facilityIds, facilityId));
        if (checkArrays(facilityIds, facilityId) === false) {
          setDoc(
            doc(db, 'users', location?.state?.docId),
            { updatedAt: new Date() },
            { merge: true }
          );
        }

        console.log('response !! update user', updatedUser);

        // const updateUser = await setDoc(
        //   doc(db, 'users', location?.state?.docId),
        //   {
        //     email,
        //     // facilityName,
        //     firstname,
        //     lastname,
        //     jobTitle,
        //     organization: organisation,
        //     facilityIds,
        //     avatarUrl: imageState ? NewAvatarUrl : deletedImg,
        //     subRole,
        //     residentIds,
        //     updatedAt: new Date()
        //   },
        //   { merge: true }
        // );
        // if (firstname !== firstName || lastname !== lastName) {
        //   const commentRef = query(
        //     collection(db, 'comments'),
        //     where('writerId', '==', location?.state?.docId)
        //   );
        //   const chatRef = query(
        //     collection(db, 'chat'),
        //     where('userIds', 'array-contains', location?.state?.docId)
        //   );

        //   // for comments
        //   getDocs(commentRef).then(async (commentData) => {
        //     commentData.forEach(async (comment) => {
        //       await updateDoc(doc(db, 'comments', comment.data()?.id), {
        //         writerName: `${firstname} ${lastname}`
        //       });
        //     });
        //   });
        //   // for chat
        //   getDocs(chatRef).then(async (chatData) => {
        //     chatData?.forEach(async (chat) => {
        //       const user = chat
        //         .data()
        //         ?.users?.map((e) =>
        //           e.id === location?.state?.docId ? { ...e, name: `${firstname} ${lastname}` } : e
        //         );
        //       await updateDoc(doc(db, 'chat', chat.data()?.threadId), {
        //         users: user
        //       });
        //     });
        //   });

        // ----------update stories collection----------------
        //   const story = query(
        //     collection(db, 'stories'),
        //     where('postedById', '==', location?.state?.docId)
        //   );
        //   getAndUpdateDoc(story, `${firstname} ${lastname}`);
        //   // ----------update noticeboards collection----------------
        //   const notice = query(
        //     collection(db, 'noticeboards'),
        //     where('postedById', '==', location?.state?.docId)
        //   );
        //   getAndUpdateDoc(notice, `${firstname} ${lastname}`);
        //   // ----------update events collection----------------
        //   const event = query(
        //     collection(db, 'events'),
        //     where('postedById', '==', location?.state?.docId)
        //   );
        //   getAndUpdateDoc(event, `${firstname} ${lastname}`);
        //   // ----------update wellness collection----------------
        //   const wellness = query(
        //     collection(db, 'wellness'),
        //     where('createdById', '==', location?.state?.docId)
        //   );
        //   getAndUpdateDoc(wellness, `${firstname} ${lastname}`);
        // }
        // -----------------when update successfully----------
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Facility Member Updated Successfully',
          showConfirmButton: false,
          timer: 2000
        });
        navigate('/home/staff-members', { replace: true });
        // --------------------------if error---------------------------
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        return NotificationManager.error(err.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                First name
              </div>
              <TextField
                fullWidth
                autoComplete="username"
                // label=""
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Last name
              </div>
              <TextField
                fullWidth
                autoComplete="username"
                type="text"
                // label=""
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Email address
              </div>
              <TextField
                fullWidth
                autoComplete="username"
                // disabled
                type="email"
                // label=""
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Box>
          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Organisation
              </div>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                open={openOrg}
                onOpen={() => {
                  getOrganisation();
                  setOpenOrg(true);
                }}
                onClose={() => {
                  setOpenOrg(false);
                }}
                onChange={(e, value, reason) => {
                  setFieldValue('organisation', value);
                  setFieldValue('facility', []);
                  if (reason === 'clear') return setSelectedOrgId('');
                  setSelectedOrgId(value?.value);
                }}
                defaultValue={{
                  title: getFieldProps('organisation').value?.title ?? '',
                  value: getFieldProps('organisation').value?.value ?? ''
                }}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                getOptionLabel={(option) => option?.title}
                options={optionOrg}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Organisation"
                    // label={!openOrg ? "Select Organisation" : undefined}
                    label={(!openOrg && !getFieldProps('organisation').value) ? "Select organisation" : undefined}
                    {...getFieldProps('organisation')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingOrg ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    error={Boolean(touched.organisation && errors.organisation)}
                    helperText={touched.organisation && errors.organisation}
                  />
                )}
              />
            </Box>

            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Group
              </div>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div style={{ width: '100%' }}>
                  <Autocomplete
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    limitTags={3}
                    id="asynchronous-demo"
                    open={open}
                    onOpen={() => {
                      console.log("id is : : ", selectedOrgId)
                      selectedOrgId && setOpen(true);
                    }}
                    // onClose={() => {
                    //   setOpen(false);
                    // }}
                    onChange={(event, newValue, reason) => {
                      if (reason === 'selectOption') {
                        setFieldValue('facility', newValue ?? '');
                        setDefaultopt(newValue);
                      } else if (reason === 'removeOption') {
                        setFieldValue('facility', newValue ?? '');
                        setDefaultopt(newValue);
                        // setRoomIds(newValue);
                      } else if (reason === 'clear') {
                        // setCheckAll(false);
                        setFieldValue('facility', []);
                        setDefaultopt([]);
                      }
                      // setFieldValue('residentsId', []);
                      // setresidentIDs([]);
                      // setCheckAllResi(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    getOptionSelected={(option, value) => value.value === option.value}
                    getOptionLabel={(option) => option.title}
                    options={options}
                    value={getFieldProps('facility')?.value}
                    loading={loading}
                    // PopperComponent={(param) => (
                    //   <PopperStyledComponent {...param}>
                    //     <Box {...param} />
                    //     <Divider />
                    //     <Box
                    //       sx={{
                    //         backgroundColor: 'white',
                    //         height: '45px',
                    //         textOverflow: 'ellipsis',
                    //         overflow: 'hidden',
                    //         whiteSpace: 'nowrap'
                    //       }}
                    //     >
                    //       <Checkbox
                    //         // checked={checkAll}
                    //         // onChange={checkAllChange}
                    //         id="check-all"
                    //         sx={{ marginRight: '8px' }}
                    //         onMouseDown={(e) => e.preventDefault()}
                    //       />
                    //       Select All
                    //     </Box>
                    //   </PopperStyledComponent>
                    // )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select Group"
                        disabled={getFieldProps('organisation').value ? false : true}
                        label={(!open && (getFieldProps('facility')?.value.length == 0)) ? "Select group" : undefined}
                        // {...getFieldProps('facility')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                        error={Boolean(touched.facility && errors.facility)}
                        helperText={touched.facility && errors.facility}
                      />
                    )}
                  />
                </div>
              </ClickAwayListener>
            </Box>

          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Job title
              </div>
              <TextField
                fullWidth
                autoComplete="username"
                // label="J"
                {...getFieldProps('jobTitle')}
                error={Boolean(touched.jobTitle && errors.jobTitle)}
                helperText={touched.jobTitle && errors.jobTitle}
              />
            </Box>

            {/* <Autocomplete
              fullWidth
              id="asynchronous-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              defaultValue={{ title: facilityName ?? '', value: facilityId ?? '' }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(e, value) => {
                console.log(value);
                setFieldValue('facilityName', value?.title || '');
                setFieldValue('facility', value?.value || '');
              }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option.title}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Facility"
                  {...getFieldProps('facility')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.facility && errors.facility)}
                  helperText={touched.facility && errors.facility}
                />
              )}
            /> */}
            {/* -------------- for Sub Role -------------- */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Role
              </div>
              <Autocomplete
                fullWidth
                onOpen={() => {
                  setOpenRole(!openRole)
                }
                }
                onClose={() => {
                  setOpenRole(!openRole)
                }
                }
                id="asynchronous-demo"
                onChange={(e, value) => {
                  console.log(value?.value);
                  setFieldValue('subRole', value?.value);
                }}
                defaultValue={{ title: subRoleFacility[subRole]?.title, value: subRole }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.title}
                options={subRoleFacility}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    // label="Select Role"
                    // label={(!getFieldProps('subRole').value && !openRole) ? "Select role" : undefined}
                    label={(!subRoleFacility?.[getFieldProps('subRole').value]?.title && !openRole) ? "Select role" : undefined}
                    {...getFieldProps('subRole')}
                    InputProps={{
                      ...params.InputProps
                    }}
                    error={Boolean(touched.subRole && errors.subRole)}
                    helperText={touched.subRole && errors.subRole}
                  />
                )}
              />
            </Box>
          </Stack>
          {/* ----------------For photo ------------------- */}
          {/* ---------------- for profile ----------- */}
          <Grid container spacing={2}>
            {image && (
              <Grid sx={{ position: "relative" }} style={{ paddingLeft: "0px" }} item xs={4} sm={4} md={2}>
                <div style={{ position: "absolute", top: "5px", right: "-8px", backgroundColor: "#9A76F5", borderRadius: "50%", padding: "5px", zIndex: 1000, display: "flex" }}>
                  <Icon
                    icon="ci:close-big"
                    color='white'
                    onClick={() => {
                      setDeleteImage(image);
                      setImage('');
                      setImageState('');
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </div>

                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle
                      alt="Image"
                      src={image && RgExp.test(image) ? image : URL.createObjectURL(imageState)}
                    />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label" sx={{ fontSize: "16px" }}>
              <span style={{ fontSize: "24px", marginRight: "2px" }}>+</span> Add Photo
              <input
                fullWidth
                type="file"
                hidden
                onChange={(e) => {
                  // console.log(e.target.files);
                  setImage(e.target.files[0]);
                  setImageState(e.target.files[0]);
                }}
                accept="image/*"
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>
          {/* ---------------- for profile ----------- */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Update Staff
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
