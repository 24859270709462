import React, { useState, useEffect, Fragment } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
// import { Document, PDFViewer } from '@react-pdf/renderer';
// import { Viewer } from '@react-pdf-viewer/core';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import file from '../../assest/file.png';
import filePNG from '../../assest/file.png';
import AviThumbnail from '../../assest/aviThumbnail.png';
import HEICThumbnail from '../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../assest/audioThumbnail.png';
import Textthumbnail from '../../assest/textThumbnail.png';
import PdfThumbnail from '../../assest/pdfThumbnail.webp';
import axios from 'axios';
import useDownloader from 'react-use-downloader';

import {
  TextField,
  Button,
  Divider,
  Avatar,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  DialogTitle,
  Tab,
  Grid,
  Card,
  styled,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Tooltip
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  addDoc
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  uploadString
} from 'firebase/storage';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { localUser } from '../../App';
import ReactPlayer from 'react-player/lazy';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Linkify from 'react-linkify';
import { PlusOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import moment from 'moment';
import chatImage from '../../assest/chat.jpg';
import { db } from '../../App';
import './chat.css';
import { uploadFile } from '../../components/_dashboard/user/UploadFile';
import gif from '../../assest/dot.gif';
import chatGroup from '../../assest/chatGroup.png';
import FamilyOrFacilityFunc from './FamilyOrFacilityFunc';
import lastMessageFunc from './lastMessageFunc';
import ApplyFilterMessageUser from './ApplyFilterMessageUser';
import ApplyFilterMessages from './ApplyFilterMessages';
import SingleLineDateFunc from './SingleLineDateFunc';
import MenuPopover from '../../components/MenuPopover';
import Scrollbar from '../../components/Scrollbar';
// import { LightBox } from '../../components/_dashboard/user/LightBox';

const user = JSON.parse(localStorage.getItem('Rel8User'));

const adminId = user?.id;

// ----------------for search style
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 280, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const chatBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const ProductDocumentStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const ProductImgStyle = {
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    // style={{height:""}}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// ************** Component **************
export default function ChatApp() {
  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [noChat, setNoChat] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [threadId, setThreadId] = useState();
  const [file, setFile] = useState([]);
  const [preview, setPreview] = useState(false);
  const [open, setOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [newUsers, setnewUsers] = useState([]);
  const [title, setTitle] = useState('');
  const [receiverId, setReceiverId] = useState(adminId);
  const [receiverIsGroup, setReceiverIsGroup] = useState(false);
  const [otherUser, setOtherUser] = useState();
  const [unReadContacts, setUnReadContacts] = useState(0);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [group, setgroup] = useState();
  const [groupUser, setgroupUser] = useState([]);
  const [docLoading, setDocLoading] = useState(false);
  // for cearch participants
  const [searchText, setsearchText] = useState('');
  // for message search box active
  const [searchChat, setSearchChat] = useState(false);
  const [globalItem, setGlobalItem] = useState(undefined);
  // for message search box
  const [SearchMessages, setSearchMessages] = useState('');

  // for Restricted users
  const [restrictedUser, setrestrictedUser] = useState(false);

  // ------for lightbox
  const [image, setImage] = useState('');
  const [imageNext, setImageNext] = useState('');
  const [imagePrev, setImagePrev] = useState('');
  const [MediaIndex, setMediaIndex] = useState(-1);
  const [MessageIndex, setMessageIndex] = useState(-1);
  const { size, percentage, download, isInProgress } = useDownloader();
  const [progressURL, setProgressURL] = useState('');

  // useEffect is used for Next and Prev Images
  useEffect(() => {
    if (
      messages.length > 0 &&
      MediaIndex >= 0 &&
      MessageIndex >= 0 &&
      MediaIndex <= messages[MessageIndex].media.length - 1
    ) {
      if (messages[MessageIndex].media[MediaIndex].downloadUrl) {
        setImage(messages[MessageIndex].media[MediaIndex].downloadUrl);
      }

      if (messages[MessageIndex].media.length - 1 > MediaIndex) {
        if (messages[MessageIndex].media[MediaIndex + 1].downloadUrl) {
          setImageNext(messages[MessageIndex].media[MediaIndex + 1].downloadUrl);
        } else {
          setImageNext('');
        }
      } else {
        setImageNext('');
      }
      if (MediaIndex > 0) {
        if (messages[MessageIndex].media[MediaIndex - 1].downloadUrl) {
          setImagePrev(messages[MessageIndex].media[MediaIndex - 1].downloadUrl);
        } else {
          setImagePrev('');
        }
      } else {
        setImagePrev('');
      }
    }
  }, [MediaIndex, MessageIndex]);

  const getSnapShotChat = async (id, index, group, item) => {
    setOtherUser([]);
    setgroup(false);

    setrestrictedUser(item?.restricted ?? false);
    if (!threadId) {
      // console.log('first Time');
    }
    if (threadId) {
      const chatRef = query(
        collection(db, 'chat', threadId, 'messages'),
        orderBy('createdAt', 'asc')
      );
      const unsubscribe = onSnapshot(chatRef, (querySnapshot) => { });

      unsubscribe();
    }
    if (group) {
      setReceiverId(id);
      setReceiverIsGroup(group);
      setgroup(true);
      const otherUser = contacts[index].users;
      setgroupUser(otherUser);
    }
    if (!group) {
      setReceiverIsGroup(group);
      const otherUser = contacts[index].users.find((i) => i.id !== adminId);
      setReceiverId(otherUser.id);
      setOtherUser(otherUser);
    }
    if (item.toReadId === adminId) {
      const toReadChaRef = doc(db, 'chat', id);

      await updateDoc(toReadChaRef, {
        toReadId: null
      });
      setUnReadContacts(unReadContacts - 1);
    }
    const chatRef = query(collection(db, 'chat', id, 'messages'), orderBy('createdAt', 'asc'));
    setThreadId(id);

    const unsubscribe = onSnapshot(chatRef, (querySnapshot) => {
      setMessages([]);
      setNoChat(false);
      const msgs = [];
      querySnapshot.forEach((doc) => {
        let me = false;
        if (doc.data().senderId === adminId) {
          me = true;
        }
        msgs.push({
          id: doc.data().id,
          text: doc.data().message,
          media: doc.data().media,
          createdAt: doc.data()?.createdAt,
          me,
          threadId: doc.data().threadId
        });
        setMessages((prev) => [
          ...prev,
          {
            id: doc.data().id,
            text: doc.data().message,
            media: doc.data().media,
            createdAt: doc.data()?.createdAt,
            me,
            threadId: doc.data().threadId
          }
        ]);
      });
      // setMessages((prev) => [...prev, msgs]);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ******************** For All users ************
  const handleClickOpen = async (heading) => {
    setTitle(heading);
    setOpen(true);

    // const chatRef = query(collection(db, 'users'), where('email', '!=', user?.email));

    let chatRef;
    if (user?.role === 'Facility Member') {
      // for use this facility => family members
      chatRef = query(
        collection(db, 'users'),
        where('email', '!=', user?.email),
        where('facilityIds', 'array-contains-any', user?.facilityIds)
      );
    } else if (user?.role === 'admin') {
      chatRef = query(collection(db, 'users'), where('email', '!=', user?.email));
    } else if (user?.role === 'Family Member') {

      // for use this residents facilitys => family members
      chatRef = query(
        collection(db, 'users'),
        where('email', '!=', user?.email),
        where('facilityIds', 'array-contains-any', user?.facilityIds)
      );
    }

    const chats = await getDocs(chatRef);

    const users = [];
    chats.forEach((result) => {
      if (!result.data()?.deletedAt) {
        if (user?.role == 'Family Member' && result.data()?.role == 'Family Member') {
          if (
            user?.residentIds?.some((item) =>
              result.data()?.residentIds?.includes(item)
            )
            && result.data()?.status !== 'pending'
          ) {
            // console.log("user residents: ", user.residentIds)
            // console.log("family residents are : ", result.data()?.residentIds)
            // console.log("common member")
          }
          else {
            return
          }
        }
        const obj = {
          id: result.data().id,
          text: `${result.data().firstname} ${result.data().lastname}`,
          avatar: result.data().avatarUrl,
          role: result.data().role,
          subRole: result.data()?.subRole ?? null,
          jobTitle: result.data()?.jobTitle ?? '',
          isSelected: false
        };
        users.push(obj);
      }
    });
    setnewUsers(users);
  };

  // const handleClickGroupOpen = async (heading) => {
  //   setGroupOpen(true);
  //   // const chatRef = query(collection(db, 'users'), where('email', '!=', 'admin@admin.com'));
  //   const chatRef = query(collection(db, 'users'), where('email', '!=', user?.email));
  //   const chats = await getDocs(chatRef);

  //   const users = [];
  //   chats.forEach((result) => {
  //     const obj = {
  //       id: result.data().id,
  //       text: `${result.data().firstname} ${result.data().lastname}`,
  //       avatar: result.data().avatarUrl,
  //       role: result.data().role,
  //       isSelected: false
  //     };
  //     users.push(obj);
  //   });
  //   setnewUsers(users);
  // };

  async function MessageCheck() {
    if (localUser?.role != 'admin') {
      let userCollection;
      console.log('res.data() ', localUser?.facilityIds);
      userCollection = query(
        collection(db, 'facilites'),
        where('id', 'in', localUser?.facilityIds)
      );

      let users = [];
      const getUsers = async () => {
        onSnapshot(userCollection, (snapshot) => {
          users = [];
          snapshot.docs.forEach((result) => {
            users.push({
              name: result.data().name,
              chatDisable: result.data()?.chatDisable ?? false
            });
          });
          const a = [];
          users.map((item) => {
            if (item.chatDisable) {
              a.push(item);
            }
          });
          if (a.length > 0) {
            //  setChatDisable(true)
            window.location.replace('/home')
          } else {
          }
        });
      };
      await getUsers();
    } else {
    }
  }

  useEffect(async () => {
    await MessageCheck()

  }, [])

  useEffect(async () => {
    const chatRef = query(
      collection(db, 'chat'),
      where('userIds', 'array-contains', adminId),
      // where('isGroup', '==', false),
      orderBy('lastMessageTime', 'desc')
    );
    setContactsLoading(true);
    const unsubscribe = onSnapshot(chatRef, (querySnapshot) => {
      setContacts([]);
      const unReedMessage = [];
      querySnapshot.forEach((result) => {
        // console.log(new Date(result.data()?.lastMessageTime.seconds * 1000).getHours());
        const date = new Date();
        date.setHours(date.getHours() - 24);
        const user = result.data().users.find((item) => item.id !== adminId);
        const obj = {
          id: result.data().threadId,
          restricted: result.data()?.restricted ?? false,
          avatar: user?.avatar,
          text:
            result.data().isGroup === false
              ? user.name?.slice(0, 20)
              : `${result.data().users[0]?.name?.length >= 16
                ? result.data().users[0]?.name?.slice(0, 13)
                : result.data().users[0]?.name
              } and ${result.data().users.length - 1} More`,
          users: result.data().users,
          // ------------------
          time: lastMessageFunc(result.data()?.lastMessageTime.seconds * 1000),
          // -------------------------
          lastMessage: result.data().lastMessage,
          mediaType: result.data().mediaType,
          toReadId: result.data().toReadId,
          active: false,
          isGroup: result.data()?.isGroup
        };

        if (result.data()?.toReadId === adminId) {
          unReedMessage.push(result.data());
        }
        setContacts((prev) => [...prev, obj]);
      });
      setUnReadContacts(unReedMessage.length);
      setContactsLoading(false);
    });

    // ******************************** For Group *******************************
    // const groupChatRef = query(
    //   collection(db, 'chat'),
    //   where('userIds', 'array-contains', adminId),
    //   where('isGroup', '==', true)
    //   // orderBy('lastMessageTime', 'desc')
    // );
    // // const groupsLocal = [];
    // // const groupsCollection = await getDocs(groupChatRef);
    // const unsubscribeGroup = onSnapshot(groupChatRef, (querySnapshot) => {
    //   setGroups([]);
    //   querySnapshot.forEach((result) => {
    //     const obj = {
    //       id: result.data().threadId,
    //       avatar: null,
    //       text: `${result.data().users[0].name} and ${result.data().users.length - 1} More`,
    //       time: result.data().lastMessageTime?.toDate().toLocaleDateString(),
    //       lastMessage: result.data().lastMessage,
    //       mediaType: result.data().mediaType,
    //       toReadId: result.data().toReadId,
    //       active: false
    //     };
    //     setGroups((prev) => [...prev, obj]);
    //   });
    // });
  }, []);

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      if (size / 1024 > 20480) {
        return NotificationManager.error('File size is greater than 20 mb');
      }
      if (file.length + arr.length > 6) {
        return NotificationManager.error('maximum 6 files to upload');
      }
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (
        mediaTypeLocal !== 'application' &&
        !arr[i].name.endsWith('.mpeg') &&
        !arr[i].name.endsWith('.heic')
      ) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        // if (arr[i].name.endsWith('.heic')) {
        //   thumbnail = HEICThumbnail;
        // }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          name: arr[i]?.name,
          size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
    }

    setFile([...file, ...obj]);

    setPreview(true);
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (globalItem) {
      try {
        await setDoc(doc(db, 'chat', globalItem.threadId), globalItem);
        setGlobalItem(undefined);
      } catch (error) {
        console.log(error.message);
      }
    }

    if (message.length === 0 && file.length === 0 && !loading) {
      return;
    }
    const Type = null;
    const medias = [];
    let itemsProcessed = 0;
    const eventsRef = doc(collection(db, 'chat', threadId, 'messages'));
    try {
      if (file.length > 0) {
        file.forEach(async (element, index, array) => {
          const mediaId = uuidv4();
          if (element.type === 'video') {
            const downloadUrl = await uploadFile(
              'chat',
              eventsRef,
              element.file,
              element.type,
              mediaId
            );
            const thumbnailId = uuidv4();
            const downloadUrlThumbnail = await uploadFile(
              'chat',
              eventsRef,
              element.thumbnail,
              'thumbnail',
              thumbnailId
            );

            medias.push({
              downloadUrl,
              thumbnailId,
              thumbnail: downloadUrlThumbnail,
              name: element.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }

          if (element.type === 'image') {
            const downloadUrl = await uploadFile(
              'chat',
              eventsRef,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element.name,
              type: element.type,
              size: null,
              id: mediaId
            });
          }

          if (element.type != 'image' && element.type != 'video') {
            const downloadUrl = await uploadFile(
              'chat',
              eventsRef,
              element.file,
              element.type,
              mediaId
            );
            const thumbnailId = uuidv4();
            medias.push({
              downloadUrl,
              thumbnailId: thumbnailId,
              thumbnail: element.thumbnail,
              name: element.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }

          itemsProcessed += 1;
          if (itemsProcessed === array.length) {
            await setDoc(eventsRef, {
              id: eventsRef.id,
              lastMsgTime: new Date(),
              media: medias,
              message: null,
              receiverId,
              senderId: adminId,
              threadId,
              senderName: `${user?.firstname} ${user?.lastname}`,
              toReadId: receiverId,
              createdAt: new Date(),
              isGroup: receiverIsGroup
            });
            // console.log(medias);
            const chatRef = doc(db, 'chat', threadId);

            await updateDoc(chatRef, {
              lastMessage: null,
              mediaType: element.type,
              senderId: adminId,
              toReadId: receiverId,
              lastMessageTime: new Date()
            });
            setLoading(false);
            setPreview(false);
            return setFile([]);
          }
        });
        return;
      }

      await setDoc(eventsRef, {
        id: eventsRef.id,
        lastMessagTime: new Date(),
        media: [],
        message,
        receiverId,
        senderId: adminId,
        threadId,
        senderName: `${user?.firstname} ${user?.lastname}`,
        toReadId: receiverId,
        createdAt: new Date(),
        isGroup: receiverIsGroup
      });

      const chatRef = doc(db, 'chat', threadId);

      await updateDoc(chatRef, {
        lastMessage: message,
        mediaType: null,
        senderId: adminId,
        toReadId: receiverId,
        lastMessageTime: new Date()
      });
      setMessage('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }

    // setMessages(messages.concat(newItem));
  };

  // const func = () => {
  //   Swal.fire({
  //     title: 'Chat with user or create group?',
  //     showDenyButton: true,
  //     confirmButtonText: 'Users',
  //     denyButtonText: `Create group`
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       handleClickOpen('Start new chat');
  //     } else if (result.isDenied) {
  // handleClickGroupOpen('Create group');
  //     }
  //   });
  // };

  // ------------------------------------------for show group dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const openGU = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ------------------------------------------for show group dropdown
  return (
    <div className="app">
      <NotificationContainer />
      <ModelPopupGroup
        title="Create Group"
        groupOpen={groupOpen}
        setGroupOpen={setGroupOpen}
        newUsers={newUsers}
        handleClose={handleClose}
      />
      <div className="contact-list" style={{ minWidth: '370px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h1 className="title">Messages</h1>
          <Tooltip title="Create chat">
            <PlusOutlined
              className="addOrCreateChatUser"
              onClick={() => handleClickOpen('Start new chat')}
              style={{
                cursor: 'pointer',
                marginTop: '15px',
                fontSize: '27px',
                fontWeight: 'bold',
                marginRight: '15px'
              }}
            />
          </Tooltip>
        </div>

        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label={
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  Chat
                  {unReadContacts > 0 && (
                    <sup style={{ color: 'red', paddingBottom: '10px', fontSize: '10px' }}>
                      {unReadContacts}
                    </sup>
                  )}
                </div>
              }
              {...a11yProps(0)}
            />
          </Tabs>
        </Box> */}
        {/* ---------------------------------- Search---------------------- */}
        <SearchStyle
          value={searchText}
          onChange={(e) => setsearchText(e.target.value)}
          placeholder="Search user..."
          type="search"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
          style={{
            marginTop: '25px',
            marginLeft: '10%'
          }}
        />
        {/* ---------------------------------- Search---------------------- */}

        <TabPanel value={value} index={0}>
          {contactsLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                marginTop: '40px'
              }}
            >
              <CircularProgress sx={{ marginX: 'auto', marginY: 'auto' }} size={50} />
            </div>
          ) : (
            <ContactList
              items={ApplyFilterMessageUser(contacts, searchText)}
              setMessages={setMessages}
              open={open}
              setOpen={setOpen}
              newUsers={newUsers}
              title={title}
              getSnapShotChat={getSnapShotChat}
              group={false}
              setMessagesLoading={setMessagesLoading}
              contacts={contacts}
              setContacts={setContacts}
              setGlobalItem={setGlobalItem}
            />
          )}
        </TabPanel>
        {/* ***************** For Group ************************ */}
        {/* <TabPanel value={value} index={1}>
          <ContactList
            items={groups}
            setMessages={setMessages}
            open={open}
            setOpen={setOpen}
            newUsers={newUsers}
            title={title}
            getSnapShotChat={getSnapShotChat}
            group
          />
        </TabPanel> */}
      </div>
      <div className="messages" style={messages.length === 0 ? chatBox : {}}>
        {!noChat && (
          <>
            {!group ? (
              <>
                <div className="chat_users_header">
                  <center>
                    <Avatar
                      alt="p"
                      src={otherUser?.avatar ?? ''}
                      sx={{ border: '2px solid #fff', boxShadow: '0px 0px 2px 2px gray' }}
                    >
                      {otherUser?.name?.slice(0, 1).toUpperCase()}
                    </Avatar>
                    <p
                      style={{
                        marginBottom: '10px',
                        color: '#fff',
                        fontSize: '13px'
                      }}
                    >
                      {otherUser?.name}
                    </p>
                  </center>
                </div>
              </>
            ) : (
              <>
                <div className="chat_users_header">
                  <center>
                    <div
                      className="chat_users_header_group"
                      role="button"
                      tabIndex={0}
                      // onMouseDown={handleOpen}
                      aria-controls={openGU ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openGU ? 'true' : undefined}
                      onMouseDown={handleClick}
                    >
                      <Avatar
                        alt="p"
                        src={groupUser[0]?.avatar ?? ''}
                        sx={{
                          marginRight: '-15px',
                          zIndex: '0',
                          border: '2px solid #fff',
                          boxShadow: '0px 0px 2px 2px gray'
                        }}
                      >
                        {groupUser[0]?.name?.slice(0, 1).toUpperCase()}
                      </Avatar>
                      <Avatar
                        alt="p"
                        src={groupUser[1]?.avatar ?? ''}
                        sx={{ zIndex: '1', border: '2px solid #fff' }}
                      >
                        {groupUser[1]?.name?.slice(0, 1).toUpperCase()}
                      </Avatar>
                      <Avatar
                        alt="p"
                        src={groupUser[2]?.avatar ?? ''}
                        sx={{
                          marginLeft: '-15px',
                          zIndex: '0',
                          border: '2px solid #fff',
                          boxShadow: '0px 0px 2px 2px gray'
                        }}
                      >
                        {groupUser[2]?.name?.slice(0, 1).toUpperCase()}
                      </Avatar>
                    </div>

                    {/* -------------------------------------------------------------------------------------------------------- */}
                    <div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openGU}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button'
                        }}
                        sx={{ marginLeft: '-75px', height: { xs: 300, sm: 400 } }}
                      >
                        <MenuItem onClick={handleClose} sx={{ width: 320 }}>
                          Participants
                        </MenuItem>
                        <Divider />
                        {groupUser?.map((notification) => (
                          <>
                            <MenuItem onClick={handleClose} sx={{ marginLeft: '10px' }}>
                              <Avatar
                                alt="p"
                                src={notification?.avatar}
                                sx={{
                                  marginLeft: '-15px',
                                  zIndex: '0',
                                  backgroundColor: '#9a76f5'
                                }}
                              >
                                {notification?.name?.slice(0, 1).toUpperCase()}
                              </Avatar>

                              <div style={{ marginLeft: '30px' }}>{notification?.name}</div>
                            </MenuItem>
                            <Divider />
                          </>
                        ))}
                      </Menu>
                    </div>
                    {/* -------------------------------------------------------------------------------------------------------- */}
                    <p
                      style={{
                        // marginTop: '-25px',
                        marginBottom: '10px',
                        color: '#fff',
                        fontSize: '13px'
                      }}
                    >
                      {groupUser[0]?.name} and {groupUser?.length - 1} others
                    </p>
                  </center>
                </div>
              </>
            )}
            {/* </center> */}
          </>
        )}

        {messagesLoading && noChat && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            {' '}
            <CircularProgress sx={{ marginX: 'auto', marginY: 'auto' }} size={100} />
          </div>
        )}
        {noChat && !messagesLoading && <img src={chatImage} alt="Start Chat" width={200} />}
        {!noChat && (
          <>
            <div className="messages-history">
              {/* <MessagesHistory items={messages} threadId={threadId} /> */}
              <MessagesHistory
                items={ApplyFilterMessages(messages, SearchMessages)}
                threadId={threadId}
                setImage={setImage}
                setMediaIndex={setMediaIndex}
                setMessageIndex={setMessageIndex}
                download={download}
                percentage={percentage}
                isInProgress={isInProgress}
                size={size}
                progressURL={progressURL}
                setProgressURL={setProgressURL}
              />
            </div>
            <Grid
              style={{
                backgroundColor: '#FAEDFF',
                display: file.length > 0 && preview ? 'flex' : 'none',
                flexWrap: 'nowrap',
                gap: '10px',
                alignItems: 'center',
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '12px',
                paddingRight: '12px',
                zIndex: '100',
                width: '100%'
              }}
              container
              ml={0}
            >
              {file.length > 0 &&
                preview &&
                file.map((item, index) => (
                  <Grid item xs={2} sm={2} md={2}>
                    {!loading && (
                      <Icon
                        icon="ci:close-big"
                        onClick={() => deleteFile(index)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {(item.type != 'image' && item.type != 'video') ||
                      item.name.endsWith('.avi') ||
                      item.name.endsWith('.mpeg') ? (
                      <Card>
                        <Box
                          sx={{
                            pt: '100%',
                            position: 'relative'
                          }}
                        >
                          <ProductDocumentStyle
                            sx={{
                              opacity: '1',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              wordWrap: 'break-word',
                              height: '100%',
                              padding: '5px'
                            }}
                          >
                            <img src={item.thumbnail} style={{ width: '90%', height: '85%' }} />
                            <p style={{ fontSize: '10px' }}>
                              {item.name.length > 17 ? item.name.slice(0, 15) + '...' : item.name}
                            </p>
                          </ProductDocumentStyle>
                        </Box>
                      </Card>
                    ) : (
                      <Card>
                        <Box sx={{ pt: '100%', position: 'relative', opacity: '1' }}>
                          <ProductImageStyle alt="Image" src={item.thumbnail ?? item.downloadUrl} />
                        </Box>
                      </Card>
                    )}
                  </Grid>
                ))}
            </Grid>

            <form className="messages-inputs" onSubmit={handleSubmit}>
              {/* --------for Search Icon-------- */}
              {!searchChat ? (
                <Tooltip title="Search message">
                  <Icon
                    icon={searchFill}
                    width="30"
                    height="30"
                    onClick={() => setSearchChat(!searchChat && true)}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Close search">
                  <Icon
                    icon={closeFill}
                    width="40"
                    height="40"
                    onClick={() => {
                      setSearchChat(!searchChat && true);
                      setSearchMessages('');
                    }}
                    style={{ cursor: 'pointer', color: searchChat && '#9A76F5' }}
                  />
                </Tooltip>
              )}

              {/* --------for search messages textField-------- */}
              {!searchChat ? (
                <>
                  <TextField
                    placeholder={loading ? "Message hasn't been sent yet" : 'Send a message'}
                    fullWidth
                    multiline
                    disabled={loading ? loading : restrictedUser}
                    maxRows={10}
                    sx={{ padding: 1, width: '88%' }}
                    onChange={handleChangeMessage}
                    value={restrictedUser ? '**** Blocked user ****' : message}
                  />
                  <Button variant="" component="label">
                    <Tooltip title="Select media">
                      <Icon icon="carbon:document-add" style={{ fontSize: '30px' }} />
                    </Tooltip>
                    <input
                      type="file"
                      hidden
                      multiple
                      disabled={!loading ? restrictedUser : true}
                      fullWidth
                      accept="image/*,video/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3, .heic"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {loading ? (
                    <CircularProgress size={30} />
                  ) : (
                    <button sx={{ padding: 1 }}>
                      <Tooltip title="Send message">
                        <Icon
                          icon="bi:send-fill"
                          className="send-message-icon"
                          style={{ fontSize: '25px' }}
                        />
                      </Tooltip>
                    </button>
                  )}
                </>
              ) : (
                <TextField
                  placeholder="Search message..."
                  sx={{ padding: 1, width: '88%' }}
                  onChange={(e) => setSearchMessages(e.target.value)}
                  value={SearchMessages}
                  type="search"
                />
              )}
            </form>
          </>
        )}
      </div>
      {image && (
        <Lightbox
          mainSrc={image}
          prevSrc={imagePrev}
          nextSrc={imageNext}
          onMoveNextRequest={() => {
            setMediaIndex(MediaIndex + 1);
          }}
          onMovePrevRequest={() => {
            setMediaIndex(MediaIndex - 1);
          }}
          onCloseRequest={() => {
            setImage('');
            setMediaIndex(-1);
            setMessageIndex(-1);
          }}
        />
      )}
    </div>
  );
}
// messages[MessageIndex].media[MediaIndex].downloadUrl
// ************** Message History **************

async function onButtonClick(x) {
  // console.log('name ', x.name);
  // const fileUrl = x.downloadUrl;
  // const fileName = x.name; // Change this to the desired file name
  // console.log('link ', x.downloadUrl);
  // fetch(fileUrl)
  //   .then((response) => response.blob())
  //   .then((blob) => {
  //     console.log('downlaoding');
  //     const url = window.URL.createObjectURL(new Blob([blob]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   })
  //   .catch((error) => console.error('Error downloading file:', error));
}

function PopupError() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  Toast.fire({
    icon: 'error',
    title: 'One File Download Once'
  });
}

const MessagesHistory = (props) =>
  []
    .concat(props.items)
    .reverse()
    .map((item, index, array) => {
      return (
        <>
          {/* {console.log(item.media.length)} */}
          {/* <Grid container spacing={2}> */}

          <div className={`message ${item.me ? 'me' : ''}`} key={item.id}>
            {item.media?.length > 0 &&
              item.media.map((x, i) => {
                const z = x.type;
                const condition = props.progressURL === x.downloadUrl && props.isInProgress != 0;
                // console.log({console.log(new Date(item?.createdAt?.seconds * 1000))});
                return (
                  // style={{display:"flex", flexDirection:"column", gap:"5px",  justifyItems:"center", alignItems:"center", maxHeight:"200px"}}
                  <div>
                    <div
                      style={{ position: 'relative', backgroundColor: condition && '#c6b1fa' }}
                      className={x.type == 'file' ? 'message-body-file' : 'message-body'}
                    >
                      {/* <Grid item xs={12} sm={12} md={12}> */}
                      {condition && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            left: '0',
                            top: '0'
                          }}
                        >
                          <CircularProgress sx={{}} size={70} />
                          <p
                            style={{
                              position: 'absolute',
                              marginY: 'auto',
                              marginX: 'auto',
                              color: 'white'
                            }}
                          >
                            {props.percentage}%
                          </p>
                        </div>
                      )}
                      {((x.type != 'image' && x.type != 'video') ||
                        (x.name && x.name.endsWith('.avi')) ||
                        (x.name && x.name.endsWith('.mpeg')) ||
                        (x.name && x.name.endsWith('.heic'))) && (
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              if (props.isInProgress) {
                                PopupError();
                                return;
                              }
                              props.setProgressURL(x.downloadUrl);
                              props.download(x.downloadUrl, x.name);
                            }}
                          >
                            {!x.name.endsWith('.pdf') &&
                              x.type == 'file' &&
                              !x.name.endsWith('.mpeg') &&
                              !x.name.endsWith('.heic') && (
                                <img width={150} height={150} src={file} />
                              )}
                            {(x.name.endsWith('.pdf') ||
                              x.type == 'audio' ||
                              x.type == 'text' ||
                              x.name.endsWith('.avi') ||
                              x.name.endsWith('.mpeg') ||
                              x.name.endsWith('.heic')) && (
                                <img width={150} height={150} src={x.thumbnail} />
                              )}
                            <div
                              style={{
                                fontSize: '12px',
                                fontSize: '12px',
                                textAlign: 'center',
                                textDecoration: 'none'
                              }}
                            >
                              <p style={{}}>{x.name.length > 15 ? x.name.slice(0, 15) : x.name}</p>
                            </div>
                          </button>
                        )}
                      {x.type === 'image' && !(x.name && x.name.endsWith('.heic')) && (
                        // <LightBox alt="image" src={x.downloadUrl}>

                        <img
                          style={{ objectFit: 'cover', cursor: 'pointer' }}
                          src={x.downloadUrl}
                          alt="img"
                          // width={150}
                          width={200}
                          height={200}
                          onClick={() => {
                            props.setMediaIndex(i);
                            props.setMessageIndex(props.items.length - index - 1);
                          }}
                        />
                        // </LightBox>
                      )}
                      {x.type === 'video' &&
                        !(x.name && x.name.endsWith('.avi')) &&
                        !(x.name && x.name.endsWith('.mpeg')) && (
                          <Box sx={{ pt: '100%', position: 'relative', width: 200, height: 200 }}>
                            <ReactPlayer
                              style={ProductImgStyle}
                              url={x.downloadUrl}
                              light={x.thumbnail}
                              playing
                              controls
                              width="100%"
                              height="100%"
                            // config={{
                            //   file: {
                            //     attributes: {
                            //       disablepictureinpicture: 'true',
                            //       controlsList: 'noplaybackrate nodownload'
                            //     }
                            //   }
                            // }}
                            />
                          </Box>
                        )}

                      {/* </Grid> */}
                    </div>
                    <div>
                      {item?.createdAt && (
                        // 9a76f5
                        <span
                          style={{
                            fontSize: '10px',
                            color: '#9a76f5',
                            float: item.me ? 'right' : 'left',
                            marginRight: item.me ? 10 : 0,
                            marginTop: -2
                          }}
                        >
                          {moment(new Date(item?.createdAt?.seconds * 1000)).format('hh:mm a')}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}

            {item?.media?.length === 0 && (
              <div>
                <div>
                  <div className="message-body">
                    <Linkify>
                      <pre style={{ whiteSpace: 'break-spaces', fontFamily: 'Arial' }}>
                        {item.text}
                      </pre>
                    </Linkify>
                  </div>
                </div>
                {item?.createdAt && (
                  <span
                    style={{
                      fontSize: '10px',
                      color: '#9a76f5',
                      float: item.me ? 'right' : 'left',
                      marginRight: item.me ? 10 : 0
                    }}
                  >
                    {moment(new Date(item?.createdAt?.seconds * 1000)).format('hh:mm a')}
                  </span>
                )}
              </div>
            )}
          </div>

          {item?.createdAt && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '11px',
                color: 'gray'
              }}
            >
              {SingleLineDateFunc(
                new Date(item?.createdAt?.seconds * 1000),
                new Date(array[index + 1]?.createdAt?.seconds * 1000)
              )}
            </div>
          )}

          {/* </Grid> */}
        </>
      );
    });

// ************** Contact Lists **************
const ContactList = (props) => {
  const contacts = props.items;
  const { setMessages } = props;
  const [activeIndex, setActiveIndex] = useState();
  const [item, setItem] = useState();

  const handleOpenChat = async (item) => {
    const index = props.items.findIndex((x) => x.id === item.id);
    setActiveIndex(index);
    props.getSnapShotChat(item.id, index, item?.isGroup, item);
  };
  useEffect(() => {
    if (item) {
      props.setMessagesLoading(true);
      handleOpenChat(item);
    }
  }, [item]);
  return (
    <ul style={{ height: '48vh', overflow: 'auto' }}>
      <ModelPopup props={props} setItem={setItem} />
      {contacts?.length === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>No Chat</div>
      )}
      {props?.items?.map((item, index) => {

        return (
          <div
            role="button"
            tabIndex={0}
            // onMouseDown={() => !item?.restricted && handleOpenChat(item)}
            onClick={() => {
              setItem(item);
            }}
          >
            {/* {console.log(item)} */}
            <li className={activeIndex === index ? 'active' : ''}>
              <div style={{ marginLeft: '-5px', marginTop: '10px' }}>
                {item.avatar ? (
                  <Avatar src={item?.avatar} />
                ) : (
                  <Avatar>{item?.text?.slice(0, 1).toUpperCase()}</Avatar>
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  paddingLeft: '10px'
                }}
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    fontSize: '12px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div>{item.text}</div>
                  <div style={{ fontSize: '10px' }}>{item.time}</div>
                </Typography>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    fontSize: '12px',
                    color: '#9A76F5',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div>{FamilyOrFacilityFunc(item.users)}</div>
                  {item.toReadId === adminId && (
                    <div
                      style={{
                        width: 5,
                        height: 5,
                        border: '5px solid #9a76f5',
                        borderRadius: '50%',
                        marginRight: 15
                      }}
                    />
                  )}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: item.toReadId === adminId ? 'black' : 'text.secondary',
                    fontSize: item.toReadId === adminId ? '13px' : '11px',
                    fontWeight: item.toReadId === adminId ? '900' : ''
                  }}
                >
                  {!item.mediaType && item.lastMessage?.length > 32 ? (
                    <span> {item?.lastMessage?.slice(0, 29)}...</span>
                  ) : (
                    item?.lastMessage
                  )}
                  {item.mediaType === 'video' && <Icon icon="bxs:video" />}
                  {item.mediaType === 'image' && <Icon icon="bi:card-image" />}
                  {item.mediaType === 'file' && <Icon icon="akar-icons:file" />}
                </Typography>
              </div>
              {/* <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                position: 'absolute',
                right: 0,
                top: 0,
                fontSize: '11px'
              }}
            >
              {item.time}
            </Typography> */}
            </li>
            <Divider />
          </div>
        )
      })}
    </ul>
  );
};

// ************** Create Chat **************
function ModelPopup({ props, setItem }) {
  const [groupOpen, setGroupOpen] = useState(false);
  const [newUsers, setnewUsers] = useState([]);

  const handleClickGroupOpen = async (heading) => {
    setGroupOpen(true);
    // const chatRef = query(collection(db, 'users'), where('email', '!=', user?.email));

    let chatRef;
    if (user?.role === 'Facility Member') {
      // for use this facility => family members
      chatRef = query(
        collection(db, 'users'),
        where('email', '!=', user?.email),
        where('facilityIds', 'array-contains-any', user?.facilityIds)
      );
    } else if (user?.role === 'admin') {
      chatRef = query(collection(db, 'users'), where('email', '!=', user?.email));
    } else if (user?.role === 'Family Member') {
      // for use this residents facilitys => family members
      chatRef = query(
        collection(db, 'users'),
        where('email', '!=', user?.email),
        where('facilityIds', 'array-contains-any', user?.facilityIds)
      );
    }

    const chats = await getDocs(chatRef);

    const users = [];
    chats.forEach((result) => {
      if (!result.data()?.deletedAt) {
        if (user?.role == 'Family Member' && result.data()?.role == 'Family Member') {
          if (
            user?.residentIds?.some((item) =>
              result.data()?.residentIds?.includes(item)
            )
            && result.data()?.status !== 'pending'
          ) {
            // console.log("user residents: ", user.residentIds)
            // console.log("family residents are : ", result.data()?.residentIds)
            // console.log("common member")
          }
          else {
            return
          }
        }
        const obj = {
          id: result.data().id,
          text: `${result.data().firstname} ${result.data().lastname}`,
          avatar: result.data().avatarUrl,
          role: result.data().role,
          subRole: result.data().subRole,
          jobTitle: result.data()?.jobTitle ?? '',
          status: result.data()?.status,
          isSelected: false,
          facilityIds: result.data()?.facilityIds ?? []
        };
        users.push(obj);

        // handleOpenChat(obj);
      }
    });
    setnewUsers(users);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const makeChatThread = async (item) => {
    let threadId = `${adminId}_${item.id}`;
    if (item.id > adminId) {
      threadId = `${item.id}_${adminId}`;
    }
    const foundThread = props.items.find((x) => x.id === threadId);
    if (foundThread) {

      props.items.map((i) => {
        if (i.users[0].id === item.id) {
          setItem(i)
        }
      })
      handleClose();
      return;
    }
    handleClose();

    const obj = {
      id: threadId,
      restricted: false,
      avatar: item.avatar,
      text: item.text?.slice(0, 20),
      users: [
        {
          id: item.id,
          role: item.role,
          subRole: item?.subRole,
          jobTitle: item?.jobTitle ?? '',
          avatar: item.avatar,
          name: item.text
        },
        {
          id: adminId,
          role: user?.role,
          subRole: user?.subRole ?? null,
          jobTitle: user?.jobTitle ?? '',
          avatar: user?.avatarUrl,
          name: `${user?.firstname} ${user?.lastname}`
        }
      ],
      // ------------------
      time: lastMessageFunc(new Date()),
      // -------------------------
      lastMessage: null,
      mediaType: null,
      toReadId: item.id,
      active: false,
      isGroup: false
    };

    props.setContacts((prev) => [obj, ...prev]);
    setItem(obj);
    const globalItem = {
      isGroup: false,
      threadId,
      lastMessage: null,
      lastMessageTime: new Date(),
      mediaType: null,
      senderId: null,
      toReadId: item.id,
      userIds: [item.id, adminId],
      users: [
        {
          id: item.id,
          role: item.role,
          subRole: item?.subRole,
          jobTitle: item?.jobTitle ?? '',
          avatar: item.avatar,
          name: item.text
        },
        {
          id: adminId,
          role: user?.role,
          subRole: user?.subRole ?? null,
          jobTitle: user?.jobTitle ?? '',
          avatar: user?.avatarUrl,
          name: `${user?.firstname} ${user?.lastname}`
        }
      ],
      createdAt: new Date()
    };
    props.setGlobalItem(globalItem);
    // try {
    //   await setDoc(doc(db, 'chat', threadId), {
    //     isGroup: false,
    //     threadId,
    //     lastMessage: null,
    //     lastMessageTime: new Date(),
    //     mediaType: null,
    //     senderId: null,
    //     toReadId: item.id,
    //     userIds: [item.id, adminId],
    //     users: [
    //       {
    //         id: item.id,
    //         role: item.role,
    //         subRole: item?.subRole,
    //         jobTitle: item?.jobTitle ?? '',
    //         avatar: item.avatar,
    //         name: item.text
    //       },
    //       {
    //         id: adminId,
    //         role: user?.role,
    //         subRole: user?.subRole ?? null,
    //         jobTitle: user?.jobTitle ?? '',
    //         avatar: user?.avatarUrl,
    //         name: `${user?.firstname} ${user?.lastname}`
    //       }
    //     ],
    //     createdAt: new Date()
    //   });
    // } catch (error) {
    //   console.log(error.message);
    // }
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <ul>
        {/* <div role="button" tabIndex={0} onMouseDown={() => console.log('Create group')}> */}
        <div role="button" tabIndex={0} onMouseDown={() => handleClickGroupOpen('Create group')}>
          <Tooltip title="Create Group" placement="top" arrow>
            <li
              style={{
                listStyleType: 'none',
                display: 'flex',
                padding: '10px 10px 10px 30px',
                cursor: 'pointer'
              }}
            // onClick={() => console.log('Create group')}
            >
              <Avatar src={chatGroup} style={{ border: '2px solid #ECECEC', opacity: '0.5' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                  Create Group
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: 1.5 }}>
                  Group
                </Typography>
              </div>
            </li>
          </Tooltip>
        </div>
        <Divider />
      </ul>
      {/* newUsers?.filter((e) => e?.status !== 'pending') */}
      <ModelPopupGroup
        title="Create Group"
        groupOpen={groupOpen}
        setGroupOpen={setGroupOpen}
        newUsers={newUsers}
        handleClose={handleClose}
      // newUsers={newUsers}
      />

      <DialogContent>
        <ul>
          {props.newUsers?.map((item, index) => (
            <div role="button" tabIndex={0} onMouseDown={() => makeChatThread(item)}>
              <li
                style={{ listStyleType: 'none', display: 'flex', padding: 10, cursor: 'pointer' }}
              >
                <Avatar src={item.avatar} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                    {item.text}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: 1.5 }}>
                    {item.role === 'Facility Member' ? item.jobTitle : ''}
                  </Typography>
                </div>
              </li>
              <Divider />
            </div>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

// ************** Create Chat Group **************
const ModelPopupGroup = (props) => {
  const [count, setCount] = useState(0);
  const [index0Ids, setIndex0Ids] = useState([]);
  const handleClose = () => {
    props.setGroupOpen(false);
    props.handleClose()
  };
  const selectUser = (index) => {
    if (props.newUsers[index].isSelected) {
      props.newUsers[index].isSelected = false;
      setIndex0Ids(props?.newUsers?.filter((e) => e?.isSelected)[0]?.facilityIds ?? []);
      return setCount(count + 1);
    }
    props.newUsers[index].isSelected = true;
    const ids = props?.newUsers?.filter((e) => e?.isSelected === true)[0]?.facilityIds;
    setIndex0Ids(ids?.length === 0 ? [0] : ids);
    setCount(count + 1);
  };

  const makeGroup = async () => {
    const users = props.newUsers.filter((item) => {
      if (item.isSelected) {
        return item.isSelected === true;
      }
    });
    const groupUsers = [];
    if (users.length <= 1) {
      return NotificationManager.error('Please select more then one user');
    }
    users.forEach((item) => {
      groupUsers.push({
        id: item.id,
        name: item.text,
        role: item.role,
        subRole: item.subRole,
        jobTitle: item.jobTitle,
        avatar: item.avatar
      });
    });
    groupUsers.push({
      id: adminId,
      name: `${user?.firstname} ${user?.lastname}`,
      role: user?.role,
      subRole: user?.subRole ?? '',
      jobTitle: user?.jobTitle ?? '',
      avatar: user?.avatarUrl
    });
    const userIds = users.map((obj) => obj.id);
    userIds.push(adminId);
    try {
      // handleClose();

      const chatRef = doc(collection(db, 'chat'));
      await setDoc(chatRef, {
        isGroup: true,
        threadId: chatRef.id ?? '',
        lastMessage: null,
        lastMessageTime: new Date(),
        mediaType: null,
        senderId: null,
        toReadId: null,
        userIds: userIds ?? '',
        users: groupUsers ?? '',
        createdAt: new Date(),
        restricted: null
      });
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={props.groupOpen} onClose={handleClose}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <DialogTitle>{props.title}</DialogTitle>

        <div
          style={{ marginRight: 30, cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onMouseDown={() => makeGroup()}
        >
          <Tooltip title="Create group" arrow>
            <Icon icon="gridicons:create" width={22} />
          </Tooltip>
        </div>
      </div>
      <div style={{ marginLeft: '20px', fontWeight: '600' }}>Participants</div>
      <hr />
      <DialogContent>
        <ul>
          {/* {props.newUsers?.map((item, index) => ( */}
          {props?.newUsers?.map((item, index) => (
            <div
              role="button"
              tabIndex={0}
              onMouseDown={() => {
                index0Ids?.length === 0 && selectUser(index);
                index0Ids?.length >= 1
                  ? item?.facilityIds?.filter((value) => index0Ids?.includes(value))?.length !==
                  0 && selectUser(index)
                  : '';
              }}
              style={{
                opacity:
                  index0Ids?.length >= 1
                    ? item?.facilityIds?.filter((value) => index0Ids?.includes(value))?.length ===
                    0 && 0.5
                    : 1
              }}
            >
              {/* {console.log(item.isSelected)} */}
              <li
                style={
                  item.isSelected
                    ? {
                      listStyleType: 'none',
                      display: 'flex',
                      padding: 10,
                      cursor: 'pointer',
                      backgroundColor: '#9A76F5',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }
                    : {
                      listStyleType: 'none',
                      display: 'flex',
                      padding: 10,
                      cursor: 'pointer'
                    }
                }
              >
                <Avatar src={item.avatar} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                    {item.text}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: 1.5 }}>
                    {item.role}
                  </Typography>
                </div>
              </li>
              <Divider />
            </div>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
