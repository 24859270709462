import { filter, forEach, result } from 'lodash';
import { getIcon, Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Box,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';
// components
import {
    collection,
    getDocs,
    doc,
    deleteDoc,
    setDoc,
    addDoc,
    onSnapshot,
    query,
    where,
    Timestamp,
    getDoc
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import moment from 'moment';
// ------------------------------------------------------------------
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db, localUser } from '../../App';
import StoriesMoreMenu from '../../components/_dashboard/stories/StoriesMoreMenu';
import NoticeMoreMenu from '../../components/_dashboard/user/NoticeMoreMenu';
import NoticeIcon from '../../assest/noticeIcon.svg';
import DocumentIcon from "@iconify/icons-ant-design/file-text"
import { DescriptionOutlined } from '@mui/icons-material';
import DocumentsMoreMenu from '../../components/_dashboard/user/DocumentsMoreMenu';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
// ------------------------End for popup box-------------------------

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
    //   { id: 'title', label: 'Title', alignRight: false },
    // { id: 'shared by', label: 'Shared by', alignRight: false },
    { id: 'fileName', label: 'Document', alignRight: false },
    { id: 'visible', label: 'Visible to', alignRight: false },
    { id: 'shared with clients', label: 'Client', alignRight: false },
    { id: 'views', label: 'Views', alignRight: false },
    { id: 'createdAt', label: 'Published Date', alignRight: false }
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (likes, title, comments) => {
    const newCityRef = doc(collection(db, 'users'));
    return setDoc(newCityRef, {
        id: newCityRef.id,
        title,
        comments,
        likes
    });
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id) =>
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDoc(doc(db, 'users', id))
                .then((res) => window.location.reload(false))
                .catch((err) => console.log(err.message));
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        }
    });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) =>
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            for (let i = 0; i < ids.length; i += 1) {
                deleteDoc(doc(db, 'users', ids[i]))
                    .then((res) => {
                        if (i === ids.length - 1) {
                            window.location.reload(false);
                        }
                    })
                    .catch((err) => console.log(err.message));
            }
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        }
    });

//* **************Update Users From Firebase**********************
export const updateUser = (id, likes, title, comments) => {
    setDoc(doc(db, 'users', id), {
        id,
        title,
        comments,
        likes
    })
        .then(() => console.log('deleted successfully'))
        .catch((err) => console.log(err.message));
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query, query2) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    const Query2 = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // console.log("query : ", query)
        // console.log("array ", array)
        return filter(
            array,
            (_user) =>
                _user?.fileName && _user?.fileName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    // console.log("1")
    const ReturnGroups = query2
        ? stabilizedThis.filter((a) => {
            // console.log('a ', a[0].facilityName);
            // console.log('query 2 ', query2);
            if (query2 == a[0].facilityName) {
                return a[0];
            }
        })
        : stabilizedThis;

    // console.log("2")
    // console.log('return group ', ReturnGroups);
    return ReturnGroups.map((el) => {
        return el[0];
    });
}

export default function Documents() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('createdAt');
    const [filterName, setFilterName] = useState('');
    const [filterByGrp, setFilterByGrp] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isLoading, setisLoading] = useState(true);
    const [Search, setSearch] = useState(false);
    const [SearchSet, setSearchSet] = useState(false);
    const [NotFound, setNotFound] = useState(false);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [USERLIST, setUSERLIST] = useState([]);
    const [clients, setClients] = useState([]);

    const [open, setOpen] = useState(false);
    const [modelRow, setModelRow] = useState('');

    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('Rel8User'));
    //* **************Get Noticeboard From Firebase**********************
    const timestamp = new Timestamp(user.createdAt.seconds, user.createdAt.nanoseconds);

    let residentsCollection;
    if (user?.role == 'admin') {
        residentsCollection = query(collection(db, 'residents'))
    } else if (user?.role == 'Facility Member' || user?.role == 'Family Member') {
        residentsCollection = query(collection(db, 'residents'), where('id', 'in', user?.residentIds.length !== 0 ? user?.residentIds : ['']))
    }

    let userCollection;

    const getDocuments = async () => {
        try {
            const ids = [];

            // Loop through `residentIds` and fetch data
            for (const id of user?.residentIds) {
                const docRef = doc(db, "residents", id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data?.documentsRef?.length) {
                        data.documentsRef.forEach((docId) => {
                            ids.push(docId?.id);
                        });
                    }
                }
            }

            console.log("ids are:", ids);
            // return ids;
            userCollection = query(collection(db, 'ClientDocuments'), where('id', 'in', ids ?? ['']));
        } catch (error) {
            console.error("Error fetching documents:", error);
            userCollection = query(collection(db, 'ClientDocuments'), where('id', 'in', ['']));
        }
    };


    if (user?.role === 'Facility Member') {
        userCollection = query(collection(db, 'ClientDocuments'), where('postedById', '==', user?.id ?? ''));
    } else if (user?.role === 'admin') {
        userCollection = collection(db, 'ClientDocuments');
    } else if (user?.role === 'Family Member') {
        // voidgetDocuments()
    }



    // const userCollection = collection(db, 'noticeboards');
    let noticeboard = [];
    let clientsData = []
    const getUsers = async () => {
        // getDocs(userCollection).then((res) => {
        // ------------------------------------------------------------------------------------
        if (user?.role == 'Family Member') {
            await getDocuments()
        }

        if (user?.residentIds?.length !== 0) {
            onSnapshot(residentsCollection, async (snapshot) => {
                clientsData = []
                for (let result of snapshot?.docs) {
                    clientsData.push({
                        id: result.data()?.id,
                        name: `${result.data()?.firstName} ${result.data()?.lastName}`,
                        nextOfKin: result.data()?.nextOfKin ?? []
                    })
                }
            })
        }


        try {
            onSnapshot(userCollection, async (snapshot) => {
                noticeboard = [];
                // snapshot.docs.forEach((result) => {
                for (let result of snapshot.docs) {
                    noticeboard.push({
                        id: result.data()?.id,
                        createdAt: result.data()?.createdAt,
                        postedById: result.data()?.postedById,
                        medias: result.data()?.medias,
                        residentsId: result.data()?.residentsId,
                        visibileFlag: result.data()?.visibileFlag,
                        authorName: result.data()?.authorName ?? '',
                        views: result.data()?.views ? result.data()?.views.length : 0,
                        documents: result.data()?.medias ? result.data()?.medias.length : 0,
                        clients: result.data()?.residentsId ? result.data()?.residentsId.length : 0,
                        visible: result.data()?.visibileFlag ? result.data().visibileFlag : false,
                        fileName: result.data()?.fileName ?? result.data()?.medias[0].name
                    });
                }
                if (!noticeboard.length) {
                    return setNotFound(true);
                }
                setUSERLIST(noticeboard);
                setClients(clientsData)
                console.log(" kkkakaka")
                console.log(clientsData)
            })
        } catch (error) {
            console.log(error)
        }
    };

    //* *****************UseEffect for users get********************
    useEffect(() => {
        getUsers();
        setisLoading(false);
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        if (Search) {
            setSearch(false);
        } else {
            setSearch(true);
        }
        setSearchSet(true);
        setFilterName(event.target.value);
    };

    useEffect(() => {
        if (filteredUsers.length === 0 && SearchSet) {
            setNotFound(true);
        }
    }, [Search]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName ?? '',
        filterByGrp ?? '');

    const isUserNotFound = filteredUsers.length === 0;
    if (isLoading)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
                <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
            </div>
        );

    // ------------------------for popup box-------------------------
    const handleClickOpen = (description, title) => {
        const data = {
            description,
            title
        };
        setModelRow(data);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // ------------------------End for popup box-------------------------

    return (
        <Box>
            <Page title="Documents | Rel8">
                <Container sx={{ width: '100%', minWidth: '100%' }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                color: '#9A76F5'
                            }}
                        >
                            {/* {getIcon(DocumentIcon)} */}
                            <DescriptionOutlined style={{ width: "30px", height: "30px" }} />
                            <p
                                style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}
                            >
                                Documents
                            </p>
                        </Box>
                        {/* {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/content/noticeboard/add-notice-board"
                startIcon={<Icon icon={plusFill} />}
              >
                Add Notice
              </Button>
            )} */}
                    </Stack>

                    <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px' }}>
                        <UserListToolbar
                            USERLIST={[]}
                            localuser={{}}
                            selected={selected}
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            collect="ClientDocuments"
                            setFilterByGrp={setFilterByGrp}
                        />

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={USERLIST.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        deletedTab={localUser.role === 'Family Member' && true}
                                    />
                                    <TableBody>
                                        {filteredUsers
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                const {
                                                    id,
                                                    createdAt,
                                                    views,
                                                    medias,
                                                    residentsId,
                                                    authorName,
                                                    visibileFlag,
                                                    postedById,
                                                    fileName
                                                } = row;
                                                // console.log(`visibleFlag is : ${visibileFlag ? 'true' : 'false'}`)
                                                const isItemSelected = selected.indexOf(id) !== -1;

                                                // console.log(clients)
                                                const clientDetails = clients?.filter((item) => item.id == residentsId[0])
                                                console.log(clientDetails)

                                                if (visibileFlag == false || localUser.role == 'admin' || localUser.role == 'Facility Member' || clientDetails[0]?.nextOfKin?.some((item) => item.id === localUser.id)) {
                                                    // console.log("included in next of kin");
                                                } else {
                                                    // console.log("not included in next of kin");
                                                    return null
                                                }

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        description="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        sx={{ textDecoration: 'none' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            {localUser.role !== 'Family Member' && (
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    onChange={(event) => handleClick(event, id)}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" width="20%">
                                                            <Typography
                                                                variant="subtitle2"
                                                                component={RouterLink}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none',
                                                                    color: '#212B36',
                                                                    fontWeight: 'bold'
                                                                }}
                                                                to="/content/document/document-details"
                                                                state={{
                                                                    id,
                                                                    date: createdAt,
                                                                    views,
                                                                    medias,
                                                                    residentsId,
                                                                    authorName,
                                                                    visibleFlag: visibileFlag,
                                                                    object: row,
                                                                    postedById,
                                                                    fileName
                                                                }}
                                                            >
                                                                {fileName.slice(0, 25) ?? medias?.[0]?.name}{fileName?.length > 25 && '...'}
                                                                {/* {authorName?.slice(0, 80)} */}
                                                            </Typography>
                                                        </TableCell>

                                                        {/* <TableCell>
                              {description.length < 25 ? (
                                description
                              ) : (
                                <>
                                  {description.slice(0, 25)}
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(description, title)}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              )}
                            </TableCell> */}
                                                        {/* <TableCell align="center">{authorName}</TableCell> */}
                                                        {/* <TableCell align="left">
                                                            {medias[0].name != undefined
                                                                ? medias[0].name.length < 35
                                                                    ? medias[0].name
                                                                    : medias[0].name.slice(0, 32) + '...'
                                                                : 'N/A'}
                                                        </TableCell> */}
                                                        {/* <TableCell align="left">  {fileName.slice(0, 25) ?? medias?.[0]?.name}{fileName?.length > 25 && '...'}</TableCell> */}
                                                        <TableCell align="left">{visibileFlag ? 'Next of kin' : 'All connections'}</TableCell>
                                                        {/* <TableCell align="left">{residentsId[0]}</TableCell> */}
                                                        <TableCell align='left'>{clientDetails[0] ? clientDetails[0]?.name : 'N/A'}</TableCell>
                                                        <TableCell align="left">{views}</TableCell>
                                                        <TableCell align="left">
                                                            {createdAt ? moment(createdAt.toDate()).format('DD MMM YYYY') : "N/A"}
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            {selected.length === 0 && user.role !== "Family Member" && (
                                                                <DocumentsMoreMenu
                                                                    id={id}
                                                                    medias={medias}
                                                                    createdAt={moment(createdAt.toDate()).format('DD MMM YYYY')}
                                                                    residentsId={residentsId}
                                                                    object={row}
                                                                    views={views}
                                                                    visibileFlag={visibileFlag}
                                                                    postedById={postedById}
                                                                    fileName={fileName}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isUserNotFound &&
                                        (NotFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                                        <UserNotFound />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                                        <SearchNotFound searchQuery={filterName} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={USERLIST.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </Card>
                </Container>
            </Page>
            {/* ***************************** Start PopUp ************************* */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
                    {modelRow?.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ***************************** End PopUp ************************* */}
        </Box>
    );
}
