import { Icon } from '@iconify/react';
import React, { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import Popover from '@mui/material/Popover';

//
import account from '../../_mocks_/account';
import { signout } from '../../components/authentication/login/LoginForm';
import UnsubscribeFCMToken from '../../components/fcmToken/UnsubscribeFCMToken';
import { localUser } from 'src/App';
import profileICon from "../../assest/profile-icon.svg"
import lockIcon from "../../assest/lock-icon.svg"
import singoutIcon from "../../assest/signout-icon.svg"
import MessageIcon from "../../assest/messageIcon.svg"
import { db } from '../../App';
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [

  {
    label: 'Profile',
    icon: profileICon,
    linkTo: '/home/profile'
  },
  {
    label: 'Messages',
    icon: MessageIcon,
    linkTo: '/home/messages'
  },
  {
    label: 'Change password',
    icon: lockIcon,
    linkTo: '/home/change-password'
  },
];

// ---------------------------------------------------logout
export const logout = async (navigate) => {
  const uid = JSON.parse(localStorage.getItem('Rel8User'))?.id;
  const fcm = localStorage.getItem('Rel8_fcm_token');

  // ---------------

  // ---------------------------------------------------
  //      For unsubscribe fcm token for notification
  // ---------------------------------------------------
  // const ids =
  //   localUser.role === 'Facility Member' ? localUser?.facilityIds : localUser?.residentIds;

  let ids = [];
  if (localUser?.role === 'admin') {
    ids = JSON.parse(localStorage.getItem('Rel8_facility_ids'));
  } else {
    ids = localUser.role === 'Facility Member' ? localUser?.facilityIds : localUser?.residentIds;
  }

  UnsubscribeFCMToken(`${uid}_comment`, fcm);
  if (ids?.length) {
    for (const i of ids) {
      // for wellness
      UnsubscribeFCMToken(`${i}_wellness`, fcm);
      // for event
      UnsubscribeFCMToken(`${i}_event`, fcm);
      // for story
      UnsubscribeFCMToken(`${i}_story`, fcm);
      // for noticeboard
      UnsubscribeFCMToken(`${i}_notice`, fcm);
      // for documents
      UnsubscribeFCMToken(`${i}_documents`, fcm);
    }
  }
  // For anyThing
  UnsubscribeFCMToken(uid, fcm);
  // for documents_on_id
  UnsubscribeFCMToken(`${uid}_documents`, fcm)
  // for story_on_id
  UnsubscribeFCMToken(`${uid}_story`, fcm)
  // for event_on_id
  UnsubscribeFCMToken(`${uid}_event`, fcm)
  // for notice_on_id
  UnsubscribeFCMToken(`${uid}_notice`, fcm)
  // For messages
  UnsubscribeFCMToken(`${uid}_message`, fcm);

  signout()
    .then(() => {
      localStorage.removeItem('Rel8User');
      localStorage.removeItem('Rel8_fcm_token');
      localStorage.removeItem('Rel8Version');
      localStorage.removeItem('Rel8_facility_ids');
      navigate('/login', { replace: true });
    })
    .catch((err) => {
      console.log(err.message);
    });
};
// ----------------------------------------------------------------------

export default function AccountPopover({ setPopOpen, version }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [unReadMessages, setUnReadContacts] = useState(0)
  const [chatDisable, setChatDisable] = React.useState(true)

  const navigate = useNavigate();
  const ID = JSON.parse(localStorage.getItem('Rel8User'))?.id;


  async function handleOpen() {

    setPopOpen(true)
    setOpen(true);
    if (localUser?.role != 'admin') {
      let userCollection;
      console.log('res.data() ', localUser?.facilityIds);
      userCollection = query(
        collection(db, 'facilites'),
        where('id', 'in', localUser?.facilityIds)
      );

      let users = [];
      const getUsers = async () => {
        onSnapshot(userCollection, (snapshot) => {
          users = [];
          snapshot.docs.forEach((result) => {
            users.push({
              name: result.data().name,
              chatDisable: result.data()?.chatDisable ?? false
            });
          });
          const a = [];
          console.log("users : ", users)
          users.map((item) => {
            if (item.chatDisable) {
              a.push(item);
            }
          });
          if (a.length > 0) {
            setChatDisable(true)
          } else {
            setChatDisable(false)
          }
        });
      };
      await getUsers();
    } else {
      setChatDisable(false)
    }

  };
  const handleClose = () => {
    setPopOpen(false)
    setOpen(false);
  };
  console.log('unReadMessages : ', unReadMessages);


  useEffect(async () => {
    console.log("working ", ID)
    const chatRef = query(
      collection(db, 'chat'),
      where('userIds', 'array-contains', ID),
    );
    const unsubscribe = onSnapshot(chatRef, (querySnapshot) => {

      const unReedMessage = [];
      querySnapshot.forEach((result) => {
        if (result.data()?.toReadId === ID) {
          unReedMessage.push(result.data());
        }
      });
      setUnReadContacts(unReedMessage.length);
    });

  }, []);



  return (
    <div style={{ position: "relative" }}>
      {(unReadMessages > 0 && !open && !chatDisable) && <div style={{ padding: "7px", backgroundColor: "red", position: "absolute", right: "3px", top: "3px", borderRadius: "50%", zIndex: 10 }}></div>}
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >

        {account.photoURL ? (
          <Avatar src={account.photoURL} alt="photoURL" />
        ) : (
          <Avatar src="" sx={{ backgroundColor: '#9A76F5' }}>
            {account.displayName?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
            {account.displayName?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
          </Avatar>
          // <Avatar src="">{account.displayName?.slice(0, 1)}</Avatar>
        )}
      </IconButton>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ marginTop: "28px" }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          horizontal: 'right'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', paddingX: "10px", paddingY: "15px", backgroundColor: '#9A76F5', minWidth: "300px" }}>
          <Box sx={{ width: 55, height: 55, borderRadius: "50%", minWidth: 55, minHeight: 55, border: "2px solid white", boxShadow: 2 }}>
            {account.photoURL ? (
              <Avatar src={account.photoURL} sx={{ width: "100%", height: "100%" }} alt="photoURL" />
            ) : (
              <Avatar src="" sx={{ backgroundColor: '#9A76F5', width: "100%", height: "100%" }}>
                {account.displayName?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
                {account.displayName?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
              </Avatar>
              // <Avatar src="">{account.displayName?.slice(0, 1)}</Avatar>
            )}
          </Box>
          <Box>
            <div style={{ fontSize: "18px", color: "white", lineHeight: "18px", marginBottom: "3px", fontWeight: 600 }}>
              {account.displayName.length < 18 ? account.displayName : account.displayName.slice(0, 15) + "..."}
            </div>
            <div style={{ fontSize: "14px", color: "white", lineHeight: "14px" }}>
              {account.email.length < 26 ? account.email : account.email.slice(0, 23) + "..."}

            </div>
          </Box>
        </Box>

        {/* <Divider sx={{ my: 1 }} /> */}
        <Box sx={{ marginY: "10px" }}>



          {MENU_OPTIONS.filter((item) => {
            if (chatDisable) {
              if (item.label != 'Messages') {
                return item;
              }
            } else {
              return item;
            }
          }).map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, paddingX: "10px", fontWeight: 'medium', }}
            >
              <img src={option.icon} style={{
                width: 24,
                height: 24, minWidth: 24, minHeight: 24, marginRight: "10px"
              }} />
              {option.label} {option.label === "Messages" && (unReadMessages > 0) && <div style={{ padding: "5px", borderRadius: "50%", backgroundColor: "red", marginLeft: "5px" }}></div>}
            </MenuItem>
          ))}

          <MenuItem
            onClick={() => logout(navigate)}
            sx={{ typography: 'body2', py: 1, paddingX: "10px", fontWeight: 'medium' }}
          >
            <img src={singoutIcon} style={{
              width: 24,
              height: 24, minWidth: 24, minHeight: 24, marginRight: "10px"
            }} />
            Log out
          </MenuItem>
        </Box>
        <div style={{ color: "#9A76F5", fontSize: "12px", fontWeight: "inherit", textAlign: "center", lineHeight: "12px", paddingBottom: "6px" }}>Version {version}</div>
        {/* <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => logout(navigate)}>
            Logout
          </Button>
        </Box> */}
      </Popover>
    </div>
  );
}
