import { Link as RouterLink, useLocation } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
// material
import { styled } from '@mui/material/styles';
import {
    Box,
    Card,
    Stack,
    Container,
    Typography,
    Avatar,
    CircularProgress,
    Tooltip,
    TablePagination
} from '@mui/material';
// import { MHidden } from '../components/@material-extend';

// layouts
// import { db } from '../..';
// import RoomForm from '../components/_dashboard/room/RoomForm';
// import FacilityMembersForm from '../components/_dashboard/FaciltiyMembers/FacilityMembersForm';
// import AuthLayout from '../layouts/AuthLayout';
// components
import {
    collection,
    getDocs,
    doc,
    deleteDoc,
    setDoc,
    addDocm,
    where,
    query,
    onSnapshot,
    Timestamp
} from 'firebase/firestore';
// import Page from '../components/Page';
// import { RegisterForm } from '../components/authentication/register';
// import AuthSocial from '../components/authentication/AuthSocial';
// import ProfileCard from '../components/_dashboard/Profile/ProfileCard';
import ProfileIcon from "../../../../assest/profile-icon.svg"
import { db, localUser } from 'src/App';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon } from '@iconify/react';
// import peopleOutline from '@iconify/icons-eva/people-outline';
import arrowOutline from '@iconify/icons-eva/arrow-ios-forward-outline';
import Page from 'src/components/Page';
import WellnessInfo from './WellnessInfo';
import { MHidden } from 'src/components/@material-extend';
import { subRoleFamily } from 'src/components/subRole/SubRole';
import Scrollbar from 'src/components/Scrollbar';
// import NoticeIcon from '../assest/noticeIcon.svg';
// import WellnessIcon from '../assest/wallnessIcon.svg';
// import Happyhdpi from '../../assest/Wellness-Happyhdpi.png';
// import Neutralhdpi from '../assest/Wellness-Neutralhdpi.png';
// import Sadhdpi from '../assest/Wellness-Sadhdpi.png';
// import ProfileMoreMenu from '../components/_dashboard/Profile/ProfileMoreMenu';
// import Label from 'src/components/Label';
// import WellnessInfo from './WellnessInfo';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    padding: theme.spacing(0, 0)
}));

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function getStoryComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingStoryComparator(a, b, orderBy)
        : (a, b) => -descendingStoryComparator(a, b, orderBy);
}

function applyStorySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) => _user?.title?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

function descendingStoryComparator(a, b, orderBy) {
    if (!a[orderBy] || !b[orderBy]) {
        if (!a[orderBy]) {
            return 1;
        }
        if (!b[orderBy]) {
            return -1;
        }
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
// ----------------------------------------------------------------------

export default function ResidentMoreInfoSF({ data }) {
    const [selectedOption, setSelectedOption] = useState('stories')
    const location = useLocation();
    const { row, deleted, notifyArray } = location?.state;
    // const staffData = data?.row
    // const staffResidentId = data?.residents




    function DocumemtsDispay() {
        const [USERS, setUSERLIST] = useState([]);
        const [NotFound, setNotFound] = useState(false);
        const [loading, setLoading] = useState(true);

        const [Notifications, setNotifications] = useState([]);
        const [NotificationsNotFound, setNotificationsNotFound] = useState(false);
        const [notificationsLoading, setNotificationsLoading] = useState(true);

        const [Family, setFamily] = useState([]);
        const [familyNotFound, setFamilyNotFound] = useState(false);
        const [familyLoading, setFamilyLoading] = useState(true);
        const [clients, setClients] = useState([]);
        const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);
        // const getDocuments = async () => {
        //         try {
        //             const ids = [];

        //             // Loop through `residentIds` and fetch data
        //             for (const id of localUser.residentIds) {
        //                 const docRef = doc(db, "residents", id);
        //                 const docSnap = await getDoc(docRef);

        //                 if (docSnap.exists()) {
        //                     const data = docSnap.data();
        //                     if (data?.documentsRef?.length) {
        //                         data.documentsRef.forEach((docId) => {
        //                             ids.push(docId?.id);
        //                         });
        //                     }
        //                 }
        //             }

        //             console.log("ids are:", ids);
        //             // return ids;
        //             userCollection = query(collection(db, 'ClientDocuments'), where('id', 'in', ids));
        //         } catch (error) {
        //             console.error("Error fetching documents:", error);
        //             userCollection = query(collection(db, 'ClientDocuments'), where('id', 'in', []));
        //         }
        //     };

        const user = JSON.parse(localStorage.getItem('Rel8User'));
        let residentsCollection;
        if (user?.role == 'admin') {
            residentsCollection = query(collection(db, 'residents'))
        } else if (user?.role == 'Facility Member' || user?.role == 'Family Member') {
            residentsCollection = query(collection(db, 'residents'), where('id', 'in', user?.residentIds.length !== 0 ? user?.residentIds : ['']))
        }

        let userCollection;
        userCollection = query(
            collection(db, 'ClientDocuments'),
            // where('facilitiesId', 'in', staffData?.facilityId ?? [''])
            where('residentsId', 'array-contains', row?.id ?? '')
        );
        // if (localUser?.role === 'Facility Member') {
        //     userCollection = query(
        //         collection(db, 'ClientDocuments'),
        //         where('postedById', '==', localUser?.id)
        //     );
        // } else if (localUser?.role === 'admin') {
        //     userCollection = collection(db, 'ClientDocuments');
        // }

        // const userCollection = collection(db, 'stories');
        let documents = [];
        let clientsData = []
        const getUsers = async () => {
            setLoading(true);
            // if (localUser?.role == 'Family Member') {
            //     return
            //     // await getDocuments()
            // }

            if (user?.residentIds?.length !== 0) {
                onSnapshot(residentsCollection, async (snapshot) => {
                    clientsData = []
                    for (let result of snapshot?.docs) {
                        clientsData.push({
                            id: result.data()?.id,
                            name: result.data()?.firstName,
                            nextOfKin: result.data()?.nextOfKin ?? []
                        })
                    }
                })
            }

            onSnapshot(userCollection, async (snapshot) => {
                documents = [];
                // snapshot.docs.forEach((result) => {
                for (let result of snapshot.docs) {
                    // console.log(result?.data())
                    documents.push({
                        id: result.data()?.id,
                        createdAt: result.data()?.createdAt,
                        postedById: result.data()?.postedById,
                        medias: result.data()?.medias,
                        residentsId: result.data()?.residentsId,
                        visibileFlag: result.data()?.visibileFlag,
                        authorName: result.data()?.authorName ?? '',
                        views: result.data()?.views ? result.data()?.views.length : 0,
                        documents: result.data()?.medias ? result.data()?.medias.length : 0,
                        clients: result.data()?.residentsId ? result.data()?.residentsId.length : 0,
                        visible: result.data()?.visibileFlag ? result.data().visibileFlag : false,
                        fileName: result.data()?.fileName ?? result.medias?.[0]?.name
                    });
                }
                if (!documents.length) {
                    setLoading(false)
                    return setNotFound(true);
                }
                setLoading(false)
                setUSERLIST(documents);
                setClients(clientsData)
                // console.log(documents)
            });
        };



        useEffect(() => {
            getUsers();
        }, []);


        const filteredUsers = applySortFilter(USERS, getComparator('desc', 'createdAt'), '');
        const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');

        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: '20px',
                    boxShadow: 20,
                    border: '0.5px solid #E3E4E6',
                    paddingY: '20px',
                    flexWrap: 'wrap',
                    gap: '15px',
                    paddingX: '30px'
                }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
                        <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
                            <TableRow sx={{ color: '#9A76F5' }}>
                                <TableCell sx={{ color: '#9A76F5' }}>Name</TableCell>

                                {/* <TableCell align="center" sx={{ color: '#9A76F5' }}>
                                    Visible to
                                </TableCell> */}
                                {/* <TableCell align="center" sx={{ color: '#9A76F5' }}>
                                    Views
                                </TableCell> */}
                                <TableCell align="left" sx={{ color: '#9A76F5' }}>
                                    Shared by
                                </TableCell>
                                <TableCell align="left" sx={{ color: '#9A76F5' }}>
                                    Published Date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ minHeight: '150px' }}>
                            {!loading &&
                                !NotFound &&
                                filteredUsers.length != 0 &&
                                filteredUsers.slice(0, 5).map((item, index) => {
                                    const {
                                        id,
                                        createdAt,
                                        views,
                                        medias,
                                        residentsId,
                                        authorName,
                                        visibileFlag,
                                        postedById,
                                        fileName
                                    } = item

                                    const clientDetails = clients?.filter((item) => item.id == residentsId[0])
                                    // console.log(clientDetails)

                                    if (visibileFlag == false || user.role == 'admin' || user.role == 'Facility Member' || clientDetails[0]?.nextOfKin?.some((item) => item.id === user.id)) {
                                        // console.log("included in next of kin");
                                    } else {
                                        // console.log("not included in next of kin");
                                        return null
                                    }


                                    return (
                                        <TableRow key={item.title + index} sx={{}}>
                                            <TableCell
                                                component={Link}
                                                to="/content/document/document-details"
                                                state={{
                                                    id,
                                                    date: createdAt,
                                                    views,
                                                    medias,
                                                    residentsId,
                                                    authorName,
                                                    visibleFlag: visibileFlag,
                                                    object: item,
                                                    postedById,
                                                    fileName
                                                }}
                                                scope="row"
                                                sx={{ fontWeight: 600, textDecoration: 'none' }}
                                            >
                                                {/* {medias.name != undefined
                          ? medias.name.length < 35
                            ? medias.name
                            : medias.name.slice(0, 32) + '...'
                          : 'N/A'} */}
                                                {/* {medias?.length !== 0 ? medias.length : 0} */}
                                                {fileName?.length > 25 ? fileName.slice(0, 25) + '...' : fileName}
                                            </TableCell>

                                            {/* <TableCell align="center">
                        <Tooltip
                          title={
                            localUser.role == 'Family Member'
                              ? familyLoading
                                ? 'Loading'
                                : familyNotFound
                                  ? 'N/A'
                                  : familyNames
                              : null
                          }
                        >
                          {localUser?.role != 'Family Member' && localUser?.role != 'Family Admin'
                            ? item.views != undefined
                              ? item.views.length
                              : '0'
                            : familyLoading
                              ? 'Loading'
                              : familyNotFound
                                ? 'N/A'
                                : familyNames}
                        </Tooltip>
                      </TableCell> */}
                                            {/* <TableCell align="center">{visibileFlag ? 'next of kin' : 'All connections'}</TableCell> */}
                                            {/* <TableCell align="center">{views}</TableCell> */}
                                            <TableCell align="left">
                                                {authorName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {moment(createdAt.toDate()).format('DD MMM YYYY')}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading && !NotFound && filteredUsers.length != 0 && (
                    <a
                        href="/content/documents"
                        style={{
                            textAlign: 'right',
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#9A76F5',
                            textDecoration: 'none',
                            display: 'flex',
                            gap: '0px',
                            justifyContent: 'end',
                            width: '100%'
                        }}
                    >
                        <p>view more</p>
                        <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
                    </a>
                )}
                {loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
                    </Box>
                )}
                {!loading && NotFound && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
                    </Box>
                )}
                {!loading && !NotFound && filteredUsers.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
                    </Box>
                )}
            </Box>
        );
    }
    // function Events() {
    //     const [USERS, setUSERLIST] = useState([]);
    //     const [NotFound, setNotFound] = useState(false);
    //     const [loading, setLoading] = useState(true);

    //     const [Notifications, setNotifications] = useState([]);
    //     const [NotificationsNotFound, setNotificationsNotFound] = useState(false);
    //     const [notificationsLoading, setNotificationsLoading] = useState(true);

    //     const [Family, setFamily] = useState([]);
    //     const [familyNotFound, setFamilyNotFound] = useState(false);
    //     const [familyLoading, setFamilyLoading] = useState(true);
    //     let userCollection;
    //     if (staffData?.role === 'Facility Member') {
    //         userCollection = query(
    //             collection(db, 'events'),
    //             where('postedById', '==', staffData?.id ?? '')
    //         );
    //     } else if (localUser?.role === 'admin') {
    //         userCollection = collection(db, 'events');
    //     } else if (localUser?.role === 'Family Member') {
    //         userCollection = query(
    //             collection(db, 'events'),
    //             where('residentsId', 'array-contains-any', localUser?.residentIds)
    //         );
    //     }

    //     // const userCollection = collection(db, 'stories');
    //     let stories = [];
    //     const getUsers = async () => {
    //         setLoading(true);
    //         // getDocs(userCollection).then((snapshot) => {
    //         // const snapshot = await getDocs(userCollection);
    //         onSnapshot(userCollection, (snapshot) => {
    //             stories = [];
    //             snapshot.docs.forEach(async (result) => {
    //                 stories.push({
    //                     id: result.data().id,
    //                     avatarUrl: 'result.data().images[0]',
    //                     title: result.data().title,
    //                     comments: result.data()?.commentsCount || 0,
    //                     likes: result.data().likes ?? [],
    //                     postedById: result.data().postedById,
    //                     startDate: result.data().startDate,
    //                     startTime: result.data().startTime,
    //                     endDate: result.data().endDate,
    //                     endTime: result.data().endTime,
    //                     createdAt: result.data().createdAt,
    //                     medias: result.data().medias ?? [],
    //                     description: result.data().description,
    //                     location: result.data().location,
    //                     endTimePeriod: result.data()?.endTimePeriod,
    //                     startTimePeriod: result.data()?.startTimePeriod,
    //                     residentsId: result.data().residentsId ?? [],
    //                     facilityName: result.data().facilityName ?? null,
    //                     facilitiesId: result.data().facilitiesId ?? null,
    //                     roomsId: result.data()?.roomsId ?? [],
    //                     authorName: result.data()?.authorName ?? '',
    //                     views: result.data()?.views ?? []
    //                 });
    //             });
    //             if (!stories.length) {
    //                 setLoading(false);
    //                 // setLoadingSpace(false);

    //                 return setNotFound(true);
    //             } else {
    //                 setNotFound(false);
    //             }
    //             setLoading(false);
    //             setUSERLIST(stories);
    //             // setLoadingSpace(false);
    //         });
    //     };

    //     // family collections
    //     let familyCollection = query(
    //         collection(db, 'residents'),
    //         where('id', 'in', staffData?.residentIds?.length !== 0 ? staffData?.residentIds : staffResidentId.length !== 0 ? staffResidentId : [''])
    //     );
    //     let family = [];
    //     const getFamily = async () => {
    //         setFamilyLoading(true);
    //         onSnapshot(familyCollection, (snapshot) => {
    //             family = [];
    //             snapshot.docs.forEach((result) => {
    //                 family.push({
    //                     id: result.data().id,
    //                     firstName: result.data().firstName,
    //                     lastName: result.data().lastName
    //                 });
    //             });
    //             if (!family.length) {
    //                 setFamilyLoading(false);
    //                 return setFamilyNotFound(true);
    //             }
    //             setFamilyLoading(false);
    //             setFamily(family);
    //         });
    //     };

    //     useEffect(() => {
    //         getUsers();
    //     }, []);

    //     useEffect(() => {
    //         if (!loading && !NotFound) {
    //             if (localUser.role == 'Family Member' || localUser.role == 'Family Admin') {
    //                 getFamily();
    //             }
    //         }
    //     }, [loading, NotFound]);

    //     const filteredUsers = applySortFilter(USERS, getComparator('desc', 'createdAt'), '');
    //     const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');
    //     let familyNames = filterFamily[0]?.firstName
    //         ? filterFamily[0]?.firstName
    //         : '' + filterFamily[1]?.firstName != undefined
    //             ? ' , ' + filterFamily[1]?.firstName
    //             : '';

    //     return (
    //         <Box
    //             sx={{
    //                 backgroundColor: 'white',
    //                 display: 'flex',
    //                 justifyContent: 'space-evenly',
    //                 alignItems: 'center',
    //                 borderRadius: '20px',
    //                 boxShadow: 20,
    //                 border: '0.5px solid #E3E4E6',
    //                 paddingY: '20px',
    //                 flexWrap: 'wrap',
    //                 gap: '15px',
    //                 paddingX: '30px'
    //             }}
    //         >
    //             <TableContainer component={Paper}>
    //                 <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
    //                     <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
    //                         <TableRow sx={{ color: '#9A76F5' }}>
    //                             <TableCell sx={{ color: '#9A76F5' }}>Title</TableCell>
    //                             <TableCell align="center" sx={{ color: '#9A76F5' }}>
    //                                 Event Date
    //                             </TableCell>
    //                             <TableCell align="center" sx={{ color: '#9A76F5' }}>
    //                                 {localUser?.role !== 'Family Member' ? 'Views' : 'Family'}
    //                             </TableCell>

    //                             <TableCell align="center" sx={{ color: '#9A76F5' }}>
    //                                 Comments
    //                             </TableCell>
    //                             <TableCell align="center" sx={{ color: '#9A76F5' }}>
    //                                 Likes
    //                             </TableCell>
    //                             <TableCell align="center" sx={{ color: '#9A76F5' }}>
    //                                 Published date
    //                             </TableCell>
    //                         </TableRow>
    //                     </TableHead>
    //                     <TableBody sx={{ minHeight: '150px' }}>
    //                         {!loading &&
    //                             !NotFound &&
    //                             filteredUsers.length != 0 &&
    //                             filteredUsers.slice(0, 5).map((item, index) => {
    //                                 const {
    //                                     id,
    //                                     title,
    //                                     description,
    //                                     comments,
    //                                     avatarUrl,
    //                                     startDate,
    //                                     startTime,
    //                                     endDate,
    //                                     endTime,
    //                                     createdAt,
    //                                     likes,
    //                                     location,
    //                                     medias,
    //                                     startTimePeriod,
    //                                     endTimePeriod,
    //                                     residentsId,
    //                                     roomsId,
    //                                     facilitiesId,
    //                                     facilityName,
    //                                     postedById,
    //                                     authorName
    //                                 } = item;

    //                                 const a = Notifications.filter((a) => {
    //                                     if (a.commentId == item.id) {
    //                                         return a;
    //                                     }
    //                                 });
    //                                 return (
    //                                     <TableRow key={item.title + index} sx={{}}>
    //                                         <TableCell
    //                                             component={Link}
    //                                             to="/content/events/event-detail"
    //                                             state={{
    //                                                 id,
    //                                                 title,
    //                                                 location,
    //                                                 description,
    //                                                 medias,
    //                                                 startDate,
    //                                                 startTime,
    //                                                 endTime,
    //                                                 endDate,
    //                                                 createdAt,
    //                                                 likes,
    //                                                 commentsCount: comments,
    //                                                 startTimePeriod,
    //                                                 endTimePeriod,
    //                                                 residentsId,
    //                                                 roomsId,
    //                                                 facilitiesId,
    //                                                 facilityName,
    //                                                 object: item
    //                                             }}
    //                                             scope="row"
    //                                             sx={{ fontWeight: 600, textDecoration: 'none' }}
    //                                         >
    //                                             {item.title != undefined
    //                                                 ? item.title.length < 35
    //                                                     ? item.title
    //                                                     : item.title.slice(0, 32) + '...'
    //                                                 : 'N/A'}
    //                                         </TableCell>
    //                                         <TableCell align="center">
    //                                             {moment(item.startDate.toDate()).format('DD MMM YYYY')}
    //                                             <br /> {moment(item.endDate.toDate()).format('DD MMM YYYY')}
    //                                         </TableCell>
    //                                         <TableCell align="center">
    //                                             <Tooltip
    //                                                 title={
    //                                                     localUser.role == 'Family Member'
    //                                                         ? familyLoading
    //                                                             ? 'Loading'
    //                                                             : familyNotFound
    //                                                                 ? 'N/A'
    //                                                                 : familyNames
    //                                                         : null
    //                                                 }
    //                                             >
    //                                                 {localUser?.role != 'Family Member' && localUser?.role != 'Family Admin'
    //                                                     ? item.views != undefined
    //                                                         ? item.views.length
    //                                                         : '0'
    //                                                     : familyLoading
    //                                                         ? 'Loading'
    //                                                         : familyNotFound
    //                                                             ? 'N/A'
    //                                                             : familyNames}
    //                                             </Tooltip>
    //                                         </TableCell>
    //                                         <TableCell align="center">{item.comments}</TableCell>
    //                                         <TableCell align="center">
    //                                             {item.likes != undefined ? item.likes.length : '0'}
    //                                         </TableCell>
    //                                         <TableCell align="center">
    //                                             {moment(item.createdAt.toDate()).format('DD MMM YYYY')}
    //                                         </TableCell>
    //                                     </TableRow>
    //                                 );
    //                             })}
    //                     </TableBody>
    //                 </Table>
    //             </TableContainer>
    //             {!loading && !NotFound && filteredUsers.length != 0 && (
    //                 <a
    //                     href="/content/events"
    //                     style={{
    //                         textAlign: 'right',
    //                         fontWeight: 500,
    //                         fontSize: '16px',
    //                         color: '#9A76F5',
    //                         textDecoration: 'none',
    //                         display: 'flex',
    //                         gap: '0px',
    //                         justifyContent: 'end',
    //                         width: '100%'
    //                     }}
    //                 >
    //                     <p>view more</p>
    //                     <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
    //                 </a>
    //             )}
    //             {loading && (
    //                 <Box
    //                     sx={{
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         width: '100%',
    //                         minHeight: '120px',
    //                         alignItems: 'center'
    //                     }}
    //                 >
    //                     <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
    //                 </Box>
    //             )}
    //             {!loading && NotFound && (
    //                 <Box
    //                     sx={{
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         width: '100%',
    //                         minHeight: '120px',
    //                         alignItems: 'center'
    //                     }}
    //                 >
    //                     <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
    //                 </Box>
    //             )}
    //             {!loading && !NotFound && filteredUsers.length === 0 && (
    //                 <Box
    //                     sx={{
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         width: '100%',
    //                         minHeight: '120px',
    //                         alignItems: 'center'
    //                     }}
    //                 >
    //                     <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
    //                 </Box>
    //             )}
    //         </Box>
    //     );
    // }
    function Stories() {
        const [USERS, setUSERLIST] = useState([]);
        const [NotFound, setNotFound] = useState(false);
        const [loading, setLoading] = useState(true);

        const [Notifications, setNotifications] = useState([]);
        const [NotificationsNotFound, setNotificationsNotFound] = useState(false);
        const [notificationsLoading, setNotificationsLoading] = useState(true);

        const [Family, setFamily] = useState([]);
        const [familyNotFound, setFamilyNotFound] = useState(false);
        const [familyLoading, setFamilyLoading] = useState(true);

        let userCollection;
        userCollection = query(
            collection(db, 'stories'),
            // where('facilitiesId', 'in', staffData?.facilityId ?? [''])
            where('residentsId', 'array-contains', row?.id ?? '')
        );
        // if (staffData?.role === 'Facility Member') {
        //     userCollection = query(
        //         collection(db, 'stories'),
        //         where('postedById', '==', staffData?.id ?? '')
        //     );
        // }

        // const userCollection = collection(db, 'stories');
        let stories = [];
        const getUsers = async () => {
            setLoading(true);
            // getDocs(userCollection).then((snapshot) => {
            // const snapshot = await getDocs(userCollection);
            onSnapshot(userCollection, (snapshot) => {
                stories = [];
                snapshot.docs?.forEach(async (result) => {
                    stories.push({
                        id: result.data()?.id,
                        avatarUrl: result?.data()?.medias && result?.data()?.medias[0]?.downloadUrl,
                        title: result.data()?.title,
                        postedById: result.data()?.postedById,
                        commentsCount: result.data()?.commentsCount ?? 0,
                        likes: result.data()?.likes ?? [],
                        // createdAt: result.data().createdAt.toDate().toDateString(),
                        createdAt: result.data()?.createdAt,
                        medias: result.data()?.medias,
                        description: result.data()?.description,
                        residentsId: result.data()?.residentsId,
                        isPublished: result.data()?.isPublished,
                        facilityName: result.data()?.facilityName,
                        facilitiesId: result.data()?.facilitiesId,
                        roomsId: result.data()?.roomsId,
                        authorName: result.data()?.authorName ?? '',
                        views: result.data()?.views ?? []
                    });
                });
                if (!stories.length) {
                    setLoading(false);
                    // setLoadingSpace(false);
                    return setNotFound(true);
                } else {
                    setNotFound(false);
                }
                setLoading(false);
                setUSERLIST(stories);
                // setLoadingSpace(false);
            });
        };

        // family collections
        let familyCollection = query(
            collection(db, 'residents'),
            where('id', '==', row?.id ?? '')
        );
        let family = [];
        const getFamily = async () => {
            setFamilyLoading(true);
            onSnapshot(familyCollection, (snapshot) => {
                family = [];
                snapshot.docs.forEach((result) => {
                    family.push({
                        id: result.data().id,
                        firstName: result.data().firstName,
                        lastName: result.data().lastName
                    });
                });
                if (!family.length) {
                    setFamilyLoading(false);
                    return setFamilyNotFound(true);
                }
                setFamilyLoading(false);
                setFamily(family);
            });
        };

        useEffect(() => {
            getUsers();
        }, []);

        useEffect(() => {
            if (!loading && !NotFound) {
                if (localUser.role !== 'Family Member') {
                } else {
                    getFamily();
                }
            }
        }, [loading, NotFound]);

        const filteredUsers = applyStorySortFilter(USERS, getStoryComparator('desc', 'createdAt'), '');
        const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');
        let familyNames = filterFamily[0]?.firstName
            ? filterFamily[0]?.firstName
            : '' + filterFamily[1]?.firstName != undefined
                ? ' , ' + filterFamily[1]?.firstName
                : '';

        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: '20px',
                    boxShadow: 20,
                    border: '0.5px solid #E3E4E6',
                    paddingY: '20px',
                    flexWrap: 'wrap',
                    gap: '15px',
                    paddingX: '30px'
                }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
                        <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
                            <TableRow sx={{ color: '#9A76F5' }}>
                                <TableCell sx={{ color: '#9A76F5' }}>Title</TableCell>

                                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                                    {localUser?.role !== 'Family Member' ? 'Views' : 'Family'}
                                </TableCell>

                                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                                    Comments
                                </TableCell>
                                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                                    Likes
                                </TableCell>
                                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                                    Published date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ minHeight: '150px' }}>
                            {!loading &&
                                !NotFound &&
                                filteredUsers.length != 0 &&
                                filteredUsers.slice(0, 5).map((item, index) => {
                                    const {
                                        id,
                                        title,
                                        description,
                                        medias,
                                        commentsCount,
                                        avatarUrl,
                                        createdAt,
                                        likes,
                                        residentsId,
                                        isPublished,
                                        // roomName,
                                        roomsId,
                                        facilitiesId,
                                        facilityName,
                                        postedById,
                                        authorName
                                    } = item;

                                    return (
                                        <TableRow key={item.title + index} sx={{}}>
                                            <TableCell
                                                component={Link}
                                                to="/content/stories/story-detail"
                                                state={{
                                                    id,
                                                    description,
                                                    medias,
                                                    likes,
                                                    commentsCount,
                                                    title,
                                                    createdAt,
                                                    residentsId,
                                                    isPublished,
                                                    roomsId,
                                                    facilitiesId,
                                                    facilityName,
                                                    object: item
                                                }}
                                                scope="row"
                                                sx={{ fontWeight: 600, textDecoration: 'none' }}
                                            >
                                                {item.title
                                                    ? item.title.length < 35
                                                        ? item.title
                                                        : item.title.slice(0, 32) + '...'
                                                    : 'N/A'}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Tooltip
                                                    title={
                                                        localUser.role == 'Family Member' &&
                                                            localUser.role == 'Family Admin' &&
                                                            familyLoading
                                                            ? 'Loading'
                                                            : familyNotFound
                                                                ? 'N/A'
                                                                : familyNames
                                                    }
                                                ></Tooltip>

                                                {localUser?.role != 'Family Member' && localUser?.role != 'Family Admin'
                                                    ? item.views != undefined
                                                        ? item.views.length
                                                        : '0'
                                                    : familyLoading
                                                        ? 'Loading'
                                                        : familyNotFound
                                                            ? 'N/A'
                                                            : familyNames}
                                            </TableCell>
                                            <TableCell align="center">{item.commentsCount}</TableCell>
                                            <TableCell align="center">
                                                {item.likes != undefined ? item.likes.length : '0'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(item.createdAt ? item.createdAt.toDate() : new Date()).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading && !NotFound && filteredUsers.length != 0 && (
                    <a
                        href="/content/stories"
                        style={{
                            textAlign: 'right',
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#9A76F5',
                            textDecoration: 'none',
                            display: 'flex',
                            gap: '0px',
                            justifyContent: 'end',
                            width: '100%'
                        }}
                    >
                        <p>view more</p>
                        <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
                    </a>
                )}
                {loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
                    </Box>
                )}
                {!loading && NotFound && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
                    </Box>
                )}
                {!loading && !NotFound && filteredUsers.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
                    </Box>
                )}
            </Box>
        );
    }
    function Notices({ SliceNumber, featuredNotice }) {
        const [USERS, setUSERLIST] = useState([]);
        const [image, setImage] = useState('');
        const [NotFound, setNotFound] = useState(false);
        const [loading, setLoading] = useState(true);
        const [rowsPerPage, setRowsPerPage] = useState(25);
        const [page, setPage] = useState(0);
        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const [Family, setFamily] = useState([]);
        const [familyNotFound, setFamilyNotFound] = useState(false);
        const [familyLoading, setFamilyLoading] = useState(true);
        const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);
        let userCollection;
        userCollection = query(
            collection(db, 'users'),
            // where('facilitiesId', 'in', staffData?.facilityId ?? [''])
            where('residentIds', 'array-contains', row?.id ?? ''), where('deletedAt', '==', null)
        );
        // if (staffData?.role === 'Facility Member') {
        //     userCollection = query(
        //         collection(db, 'noticeboards'),
        //         // where('facilitiesId', 'in', staffData?.facilityId ?? [''])
        //         where('postedById', '==', staffData?.id ?? '')
        //     );
        // }

        let noticeboard = [];
        const getUsers = async () => {
            setLoading(true);
            onSnapshot(userCollection, async (snapshot) => {
                noticeboard = [];
                for (let result of snapshot.docs) {
                    if (result.data()?.facilityIds?.includes(row?.facilityId ?? '') && localUser?.id !== result.data()?.id && result.data()?.role !== 'admin') {
                        noticeboard.push({
                            id: result.data()?.id,
                            avatarUrl: result.data()?.avatarUrl ?? '',
                            firstname: result.data()?.firstname,
                            lastname: result.data()?.lastname,
                            residentIds: result.data()?.residentIds ?? [],
                            restrictAt: result.data()?.restrictAt,
                            role: result.data()?.role,
                            status: result.data()?.status,
                            subRole: result.data()?.subRole,
                            createdAt: result.data()?.createdAt,
                            email: result.data()?.email,
                            deletedAt: result.data()?.deletedAt ?? null,
                            facilityName: result.data()?.facilityName,
                            facilityIds: result.data()?.facilityIds,
                            jobTitle: result.data()?.jobTitle,
                            organization: result.data()?.organization
                            // views: result.data()?.views ?? []
                        });
                    }
                }
                if (!noticeboard.length) {
                    setLoading(false);
                    // console.log("connections are not avaiable ")
                    return setNotFound(true);
                } else {
                    setNotFound(false);
                }
                setLoading(false);
                // console.log("connections are : ", noticeboard)
                setUSERLIST(noticeboard);

            });
        };



        useEffect(() => {
            try {
                getUsers();
            } catch {
                setLoading(false);
                setNotFound(true);
            }
        }, []);

        // useEffect(() => {
        //     if (!loading && !NotFound) {
        //         if (localUser.role != 'Family Member') {
        //         } else {
        //             getFamily();
        //         }
        //     }
        // }, [loading, NotFound]);

        // const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');
        const filteredUsers = applySortFilter(USERS, getComparator('desc', 'createdAt'), '');
        const filteredUsers2 = filteredUsers.filter((item) => { return item; });

        return (
            // <Box
            //     sx={{
            //         backgroundColor: 'white',
            //         display: 'flex',
            //         justifyContent: 'space-evenly',
            //         alignItems: 'center',
            //         borderRadius: '20px',
            //         boxShadow: 20,
            //         border: '0.5px solid #E3E4E6',
            //         paddingY: '20px',
            //         flexWrap: 'wrap',
            //         gap: '15px',
            //         paddingX: '30px'
            //     }}
            // >
            <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px' }}>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px" }} component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
                            <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
                                <TableRow sx={{ color: '#9A76F5' }}>
                                    <TableCell align='left' sx={{ color: '#9A76F5' }}>First Name</TableCell>
                                    <TableCell align='left' sx={{ color: '#9A76F5' }}>Last Name</TableCell>
                                    <TableCell align="left" sx={{ color: '#9A76F5' }}>
                                        Role
                                    </TableCell>
                                    <TableCell align="left" sx={{ color: '#9A76F5' }}>
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{ minHeight: '150px' }}>
                                {!loading &&
                                    !NotFound &&
                                    filteredUsers2.length != 0 &&
                                    filteredUsers2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                        const {
                                            id,
                                            avatarUrl,
                                            firstname,
                                            lastname,
                                            residentIds,
                                            restrictAt,
                                            role,
                                            status,
                                            subRole,
                                            createdAt,
                                            email,
                                            deletedAt,
                                            facilityName,
                                            facilityIds,
                                            jobTitle,
                                            organization
                                        } = item;

                                        return (
                                            <TableRow key={item.title + index} sx={{}}>
                                                <TableCell
                                                    // component={Link}
                                                    // to={role == 'Family Member' ? '/administration/family-members/family-more-info' : '/administration/staff-members/facility-member-more-info'}
                                                    // state={{ ...item }}
                                                    scope="row"
                                                    sx={{ fontWeight: 600, textDecoration: 'none' }}
                                                >
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        {avatarUrl ? (
                                                            // <LightBox src={avatarUrl} alt={firstname}>
                                                            <Avatar
                                                                alt={firstname}
                                                                src={avatarUrl}
                                                                style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                                                onClick={() => {
                                                                    setImage(avatarUrl);
                                                                }}
                                                            />
                                                        ) : (
                                                            // </LightBox>
                                                            <Avatar
                                                                alt={firstname}
                                                                src={avatarUrl}
                                                                style={{ backgroundColor: '#9A76F5' }}
                                                            />
                                                        )}

                                                        {/* for more info  */}

                                                        <Typography
                                                            variant="subtitle2"
                                                            // component={RouterLink}
                                                            style={{
                                                                cursor: 'pointer',
                                                                textDecoration: 'none',
                                                                color: '#212B36',
                                                                fontWeight: 'bold'
                                                            }}
                                                        // to="/administration/family-members/family-more-info"
                                                        // onClick={() => {
                                                        //     console.log(row)
                                                        // }}
                                                        // state={{ row, residents: [...row.residentIds] }}
                                                        >
                                                            {firstname?.slice(0, 13)}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {lastname}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {role == "Family Member" ? <>
                                                        Family    {subRole === 0 ? (
                                                            'Administrator'
                                                        ) : (
                                                            'Member'
                                                        )}
                                                    </> : <>{jobTitle}</>}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {/* {status == 'accepted' ? 'active' : 'pending'} */}
                                                    {status == 'accepted' ?
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                                                            <span
                                                                style={{
                                                                    backgroundColor: "#3ad638", // Parrot green color
                                                                    width: "10px",
                                                                    height: "10px",
                                                                    display: "inline-block", // Ensures the span behaves like a block for dimensions
                                                                    borderRadius: "50%",
                                                                }}
                                                            ></span>
                                                            Active
                                                        </div>
                                                        :
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                                                            <span
                                                                style={{
                                                                    backgroundColor: "#e84f51", // Parrot green color
                                                                    width: "10px",
                                                                    height: "10px",
                                                                    display: "inline-block", // Ensures the span behaves like a block for dimensions
                                                                    borderRadius: "50%",
                                                                }}
                                                            ></span>
                                                            Pending
                                                        </div>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={USERS.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {loading && !NotFound && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
                    </Box>
                )}
                {!loading && (NotFound || filteredUsers2.length === 0) && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '120px',
                            alignItems: 'center'
                        }}
                    >
                        <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
                    </Box>
                )}
                {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
            </Card>
        );
    }

    return (
        <RootStyle title="Profile | Rel8">
            <ContentStyle>

                <MHidden width="mdDown">
                    <Box sx={{ display: 'flex', gap: '40px', alignItems: 'center', marginLeft: '16px', marginTop: "20px", marginBottom: "20px" }}>
                        <div
                            onClick={() => {
                                setSelectedOption('stories');
                            }}
                            style={{
                                fontSize: '24px',
                                fontWeight: 600,
                                textUnderlineOffset: 6,
                                color: selectedOption == 'stories' ? '#9A76F5' : 'black',
                                textDecoration: selectedOption == 'stories' ? 'underline' : 'none',
                                cursor: 'pointer'
                            }}
                        >
                            My Stories
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('wellness');
                            }}
                            style={{
                                fontSize: '24px',
                                fontWeight: 600,
                                textUnderlineOffset: 6,
                                color: selectedOption == 'wellness' ? '#9A76F5' : 'black',
                                textDecoration: selectedOption == 'wellness' ? 'underline' : 'none',
                                cursor: 'pointer'
                            }}
                        >
                            Wellness
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('aboutMe');
                            }}
                            style={{
                                fontSize: '24px',
                                fontWeight: 600,
                                color: selectedOption == 'aboutMe' ? '#9A76F5' : 'black',
                                textUnderlineOffset: 6,
                                textDecoration: selectedOption == 'aboutMe' ? 'underline' : 'none',
                                cursor: 'pointer'
                            }}
                        >
                            About me
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('connection');
                            }}
                            style={{
                                fontSize: '24px',
                                fontWeight: 600,
                                color: selectedOption == 'connection' ? '#9A76F5' : 'black',
                                textUnderlineOffset: 6,
                                textDecoration: selectedOption == 'connection' ? 'underline' : 'none',
                                cursor: 'pointer'
                            }}
                        >
                            Connections
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('documents');
                            }}
                            style={{
                                fontSize: '24px',
                                fontWeight: 600,
                                color: selectedOption == 'documents' ? '#9A76F5' : 'black',
                                textUnderlineOffset: 6,
                                textDecoration: selectedOption == 'documents' ? 'underline' : 'none',
                                cursor: 'pointer'
                            }}
                        >
                            Documents
                        </div>
                    </Box>
                </MHidden>

                {/* for mobile screen */}
                <MHidden width="mdUp">
                    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center', marginLeft: '16px', marginTop: "20px", overflowX: 'scroll', paddingBottom: "10px", marginBottom: "20px" }}  >
                        <div
                            onClick={() => {
                                setSelectedOption('stories');
                            }}
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                textUnderlineOffset: 6,
                                color: selectedOption == 'stories' ? '#9A76F5' : 'black',
                                textDecoration: selectedOption == 'stories' ? 'underline' : 'none',
                                cursor: 'pointer',
                                minWidth: "80px"
                            }}
                        >
                            My Stories
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('wellness');
                            }}
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                textUnderlineOffset: 6,
                                color: selectedOption == 'wellness' ? '#9A76F5' : 'black',
                                textDecoration: selectedOption == 'wellness' ? 'underline' : 'none',
                                cursor: 'pointer',
                                minWidth: "80px"
                            }}
                        >
                            Wellness
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('aboutMe');
                            }}
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                color: selectedOption == 'aboutMe' ? '#9A76F5' : 'black',
                                textUnderlineOffset: 6,
                                textDecoration: selectedOption == 'aboutMe' ? 'underline' : 'none',
                                cursor: 'pointer',
                                minWidth: "80px"
                            }}
                        >
                            About me
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('connection');
                            }}
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                color: selectedOption == 'connection' ? '#9A76F5' : 'black',
                                textUnderlineOffset: 6,
                                textDecoration: selectedOption == 'connection' ? 'underline' : 'none',
                                cursor: 'pointer',
                                minWidth: "80px"
                            }}
                        >
                            Connections
                        </div>
                        <div
                            onClick={() => {
                                setSelectedOption('documents');
                            }}
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                color: selectedOption == 'documents' ? '#9A76F5' : 'black',
                                textUnderlineOffset: 6,
                                textDecoration: selectedOption == 'documents' ? 'underline' : 'none',
                                cursor: 'pointer',
                                minWidth: "80px"
                            }}
                        >
                            Documents
                        </div>
                    </Box>
                </MHidden>

                {selectedOption == 'stories' &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                            marginTop: '10px'
                        }}
                    >
                        <Stories />
                    </Box>
                }

                {selectedOption == 'connection' &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                            marginTop: '10px'
                        }}
                    >
                        {/* <Stories /> */}
                        {/* <p>connections</p> */}
                        <Notices />
                    </Box>
                }

                {selectedOption == 'aboutMe' &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                            marginTop: '10px'
                        }}
                    >
                        <Card
                            sx={{
                                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                                display: 'flex',
                                flexDirection: 'column',
                                width: 'full',
                                paddingY: '25px',
                                paddingX: '40px',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                marginBottom: '30px',
                                justifyItems: "center",
                                gap: "20px"
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'start', gap: '7px', width: '100%', flexDirection: "column" }}>
                                <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>About me</Box>
                                <Box sx={{ fontSize: '16px' }}>{row?.aboutMe}</Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'start', gap: '7px', width: '100%', flexDirection: "column" }}>
                                <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Likes</Box>
                                <Box sx={{ fontSize: '16px' }}>{row?.likes}</Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'start', gap: '7px', width: '100%', flexDirection: "column" }}>
                                <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Dislikes</Box>
                                <Box sx={{ fontSize: '16px' }}>{row?.dislikes}</Box>
                            </Box>

                        </Card>
                    </Box>
                }


                {selectedOption == 'documents' &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                            marginTop: '10px'
                        }}
                    >
                        {/* <Notices SliceNumber={5} featuredNotice={false} /> */}
                        <DocumemtsDispay />
                    </Box>
                }

                {selectedOption == 'wellness' &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                            marginTop: '10px'
                        }}
                    >
                        <WellnessInfo id={row?.id} deleted={deleted} root="residents" notifyArray={notifyArray} />
                    </Box>}
            </ContentStyle>
        </RootStyle>
    );
}
